import { PoS } from "features/pos/posHandler";
import axios, { handleResponse } from "utils/axios/merchant";
import axiosPublic from "utils/axios/public";

const uploadLogoPoS = async (data: FormData) => {
  return axios({
    method: "post",
    url: "/point-of-sales/upload-logo",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(handleResponse);
};

const createPointOfSale = async ({ data, updatedPoS }: { data: PoS; updatedPoS?: PoS }) => {
  console.log({ data, updatedPoS });
  let logoUrl;
  if (
    (!updatedPoS && data.logoType === "UPLOADED") ||
    (updatedPoS && data.logoType === "UPLOADED" && data.logoUrl !== updatedPoS.logoUrl)
  ) {
    const formData = new FormData();
    if (!data.fileUploaded) {
      return;
    }
    formData.append("file", data.fileUploaded);
    const logoUploaded = await uploadLogoPoS(formData);
    logoUrl = logoUploaded.data;
    console.log(logoUrl);
  }
  const pos: PoS = {
    pageHeader: data.pageHeader,
    logoType: data.logoType,
    logoUrl: logoUrl ?? data.logoUrl,
    currencyType: data.currencyType,
    localCurrency: data.localCurrency,
    cryptoSlug: data.cryptoSlug,
  };

  if (updatedPoS) {
    return axios({
      method: "put",
      url: "/point-of-sales/" + updatedPoS.id,
      data: pos,
    }).then(handleResponse);
  }

  return axios({
    method: "post",
    url: "/point-of-sales",
    data: pos,
  }).then(handleResponse);
};

const getAllPointOfSale = async (query: { page: number; limit: number; search?: string }) => {
  return axios({
    method: "get",
    url: "/point-of-sales",
    params: query,
  }).then(handleResponse);
};

const getPoSByCode = async (code: string) => {
  return axiosPublic({
    method: "get",
    url: "/point-of-sales/code/" + code,
  }).then(handleResponse);
};

const createChargeByPoSCode = async ({
  code,
  data,
}: {
  code: string;
  data: { amountRequested: string };
}) => {
  return axiosPublic({
    method: "post",
    url: "/point-of-sales/code/" + code + "/charges",
    data,
  }).then(handleResponse);
};

const updatePoSStatus = ({ id, isEnabled }: { id: string; isEnabled: boolean }) => {
  return axios({
    method: "put",
    url: "/point-of-sales/" + id,
    data: { isEnabled },
  }).then(handleResponse);
};

const deletePos = (id: string) => {
  return axios.delete("/point-of-sales/" + id).then(handleResponse);
};

export {
  createPointOfSale,
  uploadLogoPoS,
  getAllPointOfSale,
  getPoSByCode,
  createChargeByPoSCode,
  updatePoSStatus,
  deletePos,
};
