import axios, { handleResponse } from "utils/axios/merchant";
import { ISelectedWebHook, WebhookFormValues } from "../../../features/settings/types";

const subscriptionNewEndpoint = async (data: WebhookFormValues) => {
  return axios({
    method: "post",
    url: "/webhooks/all",
    data,
  });
};

const getMyWebhook = async () => {
  return axios.get("/webhooks").then(handleResponse);
};

const getAllWebhookTypes = async () => {
  return axios.get("/webhooks/types").then(handleResponse);
};

const editWebhook = async (data: ISelectedWebHook) => {
  return axios({
    method: "put",
    url: "/webhooks/endpoint",
    data,
  });
};

const sendTest = async (data: { url: string; topic: string }) => {
  return axios({
    method: "post",
    url: "/webhooks/test",
    data,
  });
};

const rotateSharedSecret = async () => {
  return axios({
    method: "put",
    url: "/merchants/rotate-shared-secret",
  });
};

const removeEndpoint = async (url: string) => {
  return axios({
    method: "delete",
    url: "/webhooks",
    params: { url },
  });
};

export {
  subscriptionNewEndpoint,
  getMyWebhook,
  getAllWebhookTypes,
  editWebhook,
  sendTest,
  rotateSharedSecret,
  removeEndpoint,
};
