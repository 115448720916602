import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const CommerceLayout = () => {
  // const { isDesktop } = useCheckDevices();

  // useEffect(() => {
  //   if (isDesktop) {
  //     window.$crisp.push(["do", "chat:show"]);
  //   } else {
  //     window.$crisp.push(["do", "chat:hide"]);
  //   }
  // }, [isDesktop]);

  return (
    <Box
      sx={{
        // background: { md: `url("/assets/images/commerce/background.png")` },
        backgroundColor: "background.main",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Outlet />
    </Box>
  );
};

export default CommerceLayout;
