import React from "react";
// MUI
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { GradientText } from "../../components/Typography";
import { BasicModalProps } from "./types";

const Modal: React.FC<Omit<BasicModalProps, "open">> = ({
  onClose,
  title,
  children,
  divider,
  DialogContentProps = { sx: { px: 10 } },
  DialogTitleProps = { sx: { px: 10 } },
  sx,
  options = {
    closeIcon: <CloseIcon />,
  },
}) => {
  return (
    <Dialog open={true} onClose={onClose} sx={sx}>
      <DialogTitle {...DialogTitleProps}>
        {options.closeIcon ? (
          <IconButton
            aria-label="close"
            onClick={onClose as any}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {options.closeIcon}
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent {...DialogContentProps}>
        {title && (
          <GradientText component="p" align="center" variant="big" mb={1.5}>
            {title}
          </GradientText>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
