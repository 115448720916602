// Theme
// import ThemeConfig from "themes";
// import ThemePrimaryColor from "components/theme/ThemePrimaryColor";
// import GlobalStyles from "themes/globalStyles";
import { FizenProvider, GlobalStyles, ModalProvider } from "@fizen/ui-components";
// Provider
import ScrollToTop from "components/ScrollToTop";
import AuthProvider from "components/auth";
// Router
import Routes from "routes";
//
import { ErrorFallback } from "ErrorsHandler";
import ThemeLocalization from "components/theme/ThemeLocalization";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { ProgressBarStyle } from "../components/LoadingScreen";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <FizenProvider>
        <ModalProvider>
          <ThemeLocalization>
            <ToastContainer />
            <GlobalStyles />
            <ProgressBarStyle />
            <ScrollToTop />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AuthProvider>
                <Routes />
              </AuthProvider>
            </ErrorBoundary>
          </ThemeLocalization>
        </ModalProvider>
      </FizenProvider>
      <ReactQueryDevtools initialIsOpen={process.env.NODE_ENV === "development"} />
    </QueryClientProvider>
  );
}
