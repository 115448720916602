import React, { useState } from "react";
// MUI
import { alpha, Box, Typography } from "@mui/material";
// Component
import { ButtonContained } from "components/button";
import Form from "components/form";
import { FizenLink } from "components/FizenLink";
import Page from "components/Page";
// Package
import * as yup from "yup";
// Hook
import useAuth from "hooks/useAuth";
import { useCountdown } from "hooks/useCountdown";
// Routes
import { PATH_AUTH } from "routes/path";
import { useLoading } from "hooks/useLoading";
import MImage from "components/@mui-extends/MImage";
// Utils
import { notifyError } from "utils/notifications";
import { commonIcons } from "utils/constants/icons";

const validationSchema = yup.object().shape({
  email: yup.string().required("This field is required").email("Email is invalid"),
});

const ResetPassword = () => {
  const { resetPassword, resendEmail } = useAuth();
  const [loading, onLoading, onEnded] = useLoading();
  // const [loadingResend, onLoadingResend, onEndResend] = useLoading();
  const [emailSent, setEmailSent] = useState(false);
  const [emailToSent, setEmailToSent] = useState(); // if not undefined, sent email is successfully
  const [countdown, , resetCountdown] = useCountdown(60, {
    acceptCountDown: !!emailSent,
    onCountDownEnd: () => {
      setEmailSent(false);
      resetCountdown();
    },
  });

  const onResetPassword = async (values) => {
    try {
      onLoading();
      await resetPassword(values);
      setEmailToSent(values.email);
      // setEmailSent(true);
    } catch (err) {
      notifyError(err.message);
    } finally {
      onEnded();
    }
  };

  const onResendEmail = async () => {
    try {
      // onLoadingResend();
      await resendEmail({ email: emailToSent, type: "forgot-password" });
      setEmailSent(true);
    } catch (err) {
      notifyError(err.message);
    } finally {
      // onEndResend();
      setEmailSent(true);
    }
  };

  return (
    <Page title="Reset Password | Fizen Payment Gateway">
      <div className="flex flex-col w-full h-screen justify-center items-center gap-[12px] 2xl:gap-[24px]">
        <Typography sx={{ fontSize: 36, fontWeight: 600, color: "white" }}>Fizen Pay</Typography>
        {!emailToSent ? (
          <Box
            sx={{
              boxShadow: "rgb(0 0 0 / 6%) 0px 4px 4px, rgb(0 0 0 / 8%) 0px 8px 18px",
              width: { xs: 9 / 10, md: 650 },
              bgcolor: "background.grey",
              borderRadius: "9px",
            }}
            py={5}
            px={{ xs: 3, lg: 8 }}
          >
            <Typography
              component="p"
              variant="big"
              sx={{ textAlign: "center", mb: 3, color: "text.paper" }}
            >
              Reset password
            </Typography>
            <Typography
              component="p"
              variant="h6"
              sx={{ textAlign: "center", color: "text.paper" }}
              mb={4}
            >
              We'll email you a link to reset your password.
            </Typography>
            <Form
              initialValue={{ email: "" }}
              formModel={{
                email: {
                  type: "string",
                  label: "Email",
                  name: "email",
                  sx: {
                    width: "100%",
                    "& .MuiFilledInput-root": {
                      transition: (theme) =>
                        theme.transitions.create([
                          "border-color",
                          "background-color",
                          "box-shadow",
                        ]),
                      "&:hover": {
                        backgroundColor: "white",
                        borderColor: (theme) => theme.palette.warning.dark,
                        boxShadow: (theme) =>
                          `${alpha(theme.palette.warning.dark, 0.25)} 0 0 0 2px`,
                      },
                      "&.Mui-focused": {
                        backgroundColor: "white",
                        borderColor: (theme) => theme.palette.warning.dark,
                      },
                    },
                  },
                },
              }}
              // style="dark"
              validationSchema={validationSchema}
              formButton={({ currentValue }) => (
                <div className="flex flex-col gap-5 items-center mt-5">
                  <ButtonContained
                    disabled={!currentValue.email}
                    fullWidth
                    sx={{ height: 50 }}
                    type="submit"
                    loading={loading}
                  >
                    Send email to reset password
                  </ButtonContained>
                  <FizenLink to={PATH_AUTH.signIn}>
                    <Typography variant="text" sx={{ color: "text.paper" }}>
                      Cancel
                    </Typography>
                  </FizenLink>
                </div>
              )}
              onFinish={onResetPassword}
            />
          </Box>
        ) : (
          <Box
            sx={{
              boxShadow: "rgb(0 0 0 / 6%) 0px 4px 4px, rgb(0 0 0 / 8%) 0px 8px 18px",
              width: { xs: 9 / 10, md: 550 },
              bgcolor: "background.grey",
              borderRadius: "9px",
            }}
            py={5}
            px={8}
          >
            <div className="flex flex-col justify-center items-center w-full gap-8">
              <MImage src={commonIcons.email} sx={{ maxWidth: 120, maxHeight: 120 }} />
              <div className="flex flex-col gap-2 items-center">
                <Typography variant="big" sx={{ color: "text.paper" }}>
                  Password Reset Requested
                </Typography>
                <Typography
                  component="p"
                  variant="text"
                  sx={{ color: "text.paper", textAlign: "center", width: { lg: "65%" } }}
                >
                  We will send you a password reset email if the email you provided matches with an
                  account in our records.
                </Typography>
              </div>
              <div className="flex flex-col gap-3 w-full">
                <FizenLink to={PATH_AUTH.signIn} sx={{ width: "100%" }}>
                  <ButtonContained fullWidth sx={{ height: 44 }}>
                    Sign in
                  </ButtonContained>
                </FizenLink>
                <Typography
                  component="p"
                  align="center"
                  variant="text"
                  sx={{ color: "text.paper", mt: 1 }}
                >
                  {emailSent ? (
                    `Email sent! You can click Resend again in ${countdown}s`
                  ) : (
                    <>
                      Didn’t receive the email?{" "}
                      <Typography
                        component="span"
                        variant="text"
                        sx={{ color: "warning.dark", cursor: "pointer" }}
                        onClick={onResendEmail}
                      >
                        Resend
                      </Typography>
                    </>
                  )}
                </Typography>
              </div>
            </div>
          </Box>
        )}
      </div>
    </Page>
  );
};

export default ResetPassword;
