import { atom } from "recoil";
import { Default2FAProps, DefaultUserProps, UserFields } from "./types";

export const defaultUserProfile: DefaultUserProps<UserFields> = {
  user: undefined,
  isAuthenticated: false,
  isInitialize: false,
};

export const userProfile = atom<DefaultUserProps<UserFields>>({
  key: "userProfile",
  default: defaultUserProfile,
});

export const userToken = atom<string | undefined>({
  key: "userToken",
  default: undefined,
});

export const default2FAProps: Default2FAProps = {
  title: "",
  visible: false,
  onClose: undefined,
  onOK: undefined,
  buttonConfirmProps: {},
  acceptPassword: false,
};

export const defaultGenerateReportProps: { visible: boolean } = {
  visible: false,
};

export const form2FAProps = atom<Default2FAProps>({
  key: "form2FAProps",
  default: default2FAProps,
});

export const generateReportProps = atom<{ visible: boolean }>({
  key: "generateReportProps",
  default: defaultGenerateReportProps,
});

export const currentTabDashboard = atom<string | undefined>({
  key: "currentTabDashboard",
  default: undefined,
});

export const connection = atom<boolean>({
  key: "connection",
  default: true,
});
