import axios, { handleResponse } from "utils/axios/merchant";
import { TransactionType } from "../../../hooks/useGenerateReports";
import { QueryStatus } from "../../../utils/constants/payments";

const getAllPayments = async (query: { search?: string; status?: QueryStatus | "" }) => {
  return axios({
    method: "get",
    url: "/payments",
    params: query,
  }).then(handleResponse);
};

const getAllCharges = async (query: {
  page: number;
  limit: number;
  search?: string;
  status?: QueryStatus | "";
}) => {
  return axios({
    method: "get",
    url: "/charges",
    params: query,
  }).then(handleResponse);
};

const getPaymentsById = async (id: string) => {
  return axios({
    url: `/payments/${id}`,
  }).then(handleResponse);
};

const resolvePayment = async (id: string | undefined, data: { remark?: string }) => {
  if (!id) {
    throw new Error("Missing id");
  }

  return axios({
    method: "post",
    url: `/charges/code/${id}/resolve`,
    data,
  }).then(handleResponse);
};

const refundPayment = async (id: string | undefined, data: { remark?: string }) => {
  if (!id) {
    throw new Error("Missing id");
  }

  return axios({
    method: "post",
    url: `/charges/code/${id}/refund`,
    data,
  }).then(handleResponse);
};

const generateReport = async (params: {
  startTime: number;
  endTime: number;
  transactionType: TransactionType;
}) => {
  return axios({
    method: "get",
    url: `/payments/report`,
    params,
  }).then(handleResponse);
};

export {
  getAllPayments,
  getPaymentsById,
  resolvePayment,
  refundPayment,
  generateReport,
  getAllCharges,
};
