import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import React, { createContext } from "react";
import { Network } from "../blockchains/configs";

export interface AppProvidersProps {
  rpcUrl?: string;
  provider?: JsonRpcProvider | Web3Provider;
  network?: Network;
  scanLink?: string;
}

export interface AppProviders {
  jsonRpcProvider?: AppProvidersProps;
  web3Provider?: AppProvidersProps;
}
interface BlockchainProviderProps {
  onSetAppProvider: (prop: keyof AppProviders, provider: AppProvidersProps) => void;
  appProviders: AppProviders;
}

const initialState = {
  appProviders: {
    jsonRpcProvider: undefined,
    web3Provider: undefined,
  },
  onSetAppProvider: () => {},
};

const BlockChainContext = createContext<BlockchainProviderProps>(initialState);

function BlockChainProvider({ children }: { children: React.ReactNode | JSX.Element }) {
  const [appProviders, setAppProviders] = React.useState(initialState.appProviders);

  const onSetAppProvider = (prop: keyof AppProviders, provider: AppProvidersProps) => {
    setAppProviders({
      ...appProviders,
      [prop]: provider,
    });
  };

  return (
    <BlockChainContext.Provider value={{ appProviders, onSetAppProvider }}>
      {children}
    </BlockChainContext.Provider>
  );
}

export { BlockChainProvider, BlockChainContext };
