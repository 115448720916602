import { enUS, thTH, viVN } from "@mui/material/locale";
import { useTranslation } from "react-i18next";
import { localesIcons } from "utils/constants/icons";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: localesIcons.us,
  },
  {
    label: "Vietnamese",
    value: "vi",
    systemValue: viVN,
    icon: localesIcons.vn,
  },
  {
    label: "Thailand",
    value: "th",
    systemValue: thTH,
    icon: localesIcons.th,
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
