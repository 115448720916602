import axiosMerchant from "utils/axios/merchant";
import { default as axios, default as axiosPublic, handleResponse } from "utils/axios/public";
import { FormLoginValues } from "../index";

export const login = async (data: FormLoginValues) => {
  return axios({
    method: "post",
    url: "/login",
    data,
  }).then(handleResponse);
};

export const resetPassword = async (data: { email: string }) => {
  return axios({
    method: "post",
    url: "/request-forgot-password",
    data,
  }).then(handleResponse);
};

export const changeNewPassword = async (data: {
  forgotPassword: string;
  password: string;
  confirmedPassword: string;
}) => {
  return axios({
    method: "post",
    url: "/merchants/reset-password",
    data,
  }).then(handleResponse);
};

export const activeAccount = async (data: { email: string; activateCode: string }) => {
  return axios({
    method: "get",
    params: data,
    url: "/verify",
  });
};

export const confirmSupportEmail = async ({
  supportEmail,
  activateCode,
}: {
  supportEmail: string;
  activateCode: string;
}) => {
  return axios({
    method: "get",
    url: "/verify-support-email",
    params: { supportEmail, activateCode },
  });
};

export const getProfile = async () => {
  return axiosMerchant({
    method: "get",
    url: "/merchants/me",
  }).then(handleResponse);
};

export const resendEmail = async (params: { email: string; type: any }) => {
  return axiosPublic({
    method: "put",
    url: "/merchants/emails/resend",
    params,
  });
};

export const checkExpire = async (params: { email: string; code: string }) => {
  return axiosPublic({
    method: "get",
    url: "/merchants/forgot-password/check-expired",
    params,
  }).then(handleResponse);
};
