import { useModal } from "@fizen/ui-components";
import { Link, Stack, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Banner from "components/Banner";
import GenerateReports from "components/dialog/GenerateReports";
import Form2FaModal from "components/form/Form2FAModal";
import { WithAccountSetup } from "components/hoc/wrapper/WithAccountSetup";
import WithMerchantHost from "components/hoc/wrapper/WithMerchant";
import { WithSignin } from "components/hoc/wrapper/WithSignin";
import useCollapseDrawer from "hooks/useCollapseDrawer";
import { NavBarHeader } from "layouts/dashboard/Header";
import Sidebar from "layouts/dashboard/Sidebar";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { isTestnet } from "utils/configs";
import GoMainnetDialog from "../../components/dialog/GoMainnetDialog";
// import { useRecoilValue } from "recoil";
// import { userProfile } from "recoil/atom";
// import LoadingScreen from "components/LoadingScreen";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 70;

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 10 + (isTestnet ? 80 : 0),
  paddingBottom: 20,
  paddingLeft: 10,
  paddingRight: 10,
  [theme.breakpoints.up("md")]: {
    paddingTop: APP_BAR_DESKTOP + 20 + (isTestnet ? 30 : 0),
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 20 + (isTestnet ? 30 : 0),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

export function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { openModal } = useModal();

  // useHelperChat();

  // useEffect(() => {
  //   window.$crisp.push(["do", "chat:show"]);
  // }, []);

  const openGoMainnetModal = () => openModal(GoMainnetDialog);

  return (
    <WithMerchantHost>
      <WithSignin>
        <Form2FaModal />
        <WithAccountSetup>
          {/*<Header />*/}

          <GenerateReports />
          {isTestnet && (
            <Banner
              title={
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={{ xs: 1, md: 0 }}
                  sx={{ width: "100%", px: 2, py: 1 }}
                >
                  <Link
                    href="https://t.me/fizen_get_test_tokens_bot"
                    target="_blank"
                    rel="noreferrer noopener"
                    underline="none"
                    sx={{
                      color: "primary.main",
                      fontSize: 14,
                      fontWeight: 500,
                      visibility: "hidden",
                      display: { xs: "none", md: "inline" },
                    }}
                  >
                    Get test tokens
                  </Link>
                  <Typography variant="text500" sx={{ color: "white" }}>
                    You are using test mode{" "}
                    <Typography
                      component="span"
                      variant="text500"
                      sx={{ color: "warning.dark", cursor: "pointer" }}
                      onClick={openGoMainnetModal}
                    >
                      Go Mainnet
                    </Typography>
                  </Typography>
                  <Link
                    href="https://t.me/fizen_get_test_tokens_bot"
                    target="_blank"
                    rel="noreferrer noopener"
                    underline="none"
                    sx={{ color: "warning.dark", fontSize: 14, fontWeight: 500 }}
                  >
                    Get test tokens
                  </Link>
                </Stack>
              }
            />
          )}
          <div className="flex overflow-hidden min-h-full">
            <NavBarHeader onOpenSidebar={() => setOpen(true)} />
            <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle
              sx={{
                transition: theme.transitions.create("margin", {
                  duration: theme.transitions.duration.complex,
                }),
                background: "#F3F2F6",
                backgroundSize: "cover",
                ...(collapseClick && {
                  ml: "102px",
                }),
              }}
            >
              <Outlet />
            </MainStyle>
          </div>
        </WithAccountSetup>
      </WithSignin>
    </WithMerchantHost>
  );
}
