import { DashboardLayout } from "layouts/dashboard/DashboardLayout";
import React, { lazy, Suspense } from "react";
// import { Dashboard } from "pages/dashboard";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
// import Loading from "components/Loading";
import IosRedirect from "components/IOSRedirect";
import LoadingScreen from "components/LoadingScreen";
import CommerceLayout from "layouts/CommerceLayout";
import RootLayout from "layouts/RootLayout";
import NewPassword from "pages/new-password";
import Expired from "pages/new-password/Expired";
import ResetPassword from "pages/reset-password";
import SignIn from "pages/signin";
import SignUp from "pages/signup";
import Confirmation from "pages/signup/Confirmation";
import PointsOfSaleLayout from "../layouts/PointsOfSaleLayout";
// import { useRecoilValue } from "recoil";
// import { userProfile } from "../recoil/atom";

const Loadable =
  <C extends React.ComponentType<any>, P extends React.ComponentProps<C>>(Component: C) =>
  (props: P) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

// const Authenticated = () => {
//   const { isAuthenticated, user, isInitialize } = useRecoilValue(userProfile);
//
//   if (!isAuthenticated || !user || !isInitialize) {
//     return <Navigate to="/signin" />;
//   }
//
//   return <Navigate to="balances" />;
// };

export default function Routes() {
  return useRoutes([
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          // element: <Dashboard />,
          element: <Navigate to="balances" />,
        },
        {
          path: "invoices",
          children: [
            {
              path: "",
              element: <Invoices />,
            },
          ],
        },
        {
          path: "pos",
          children: [
            {
              path: "",
              element: <PoS />,
            },
            {
              path: "create",
              element: <PoSForm />,
            },
            {
              path: ":id/update",
              element: <PoSForm />,
            },
          ],
        },
        {
          path: "payments",
          children: [
            {
              path: "",
              element: <Payments />,
            },
          ],
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "crypto-settings",
          children: [
            {
              path: "",
              element: <CryptoSettings />,
            },
            {
              path: "create-new-wallet",
              element: <CreateNewWallet />,
            },
          ],
        },
        {
          path: "balances",
          children: [
            {
              path: "",
              element: <Balances />,
            },
            {
              path: "history/:tokenAddress",
              element: <WithdrawalHistory />,
            },
          ],
        },
      ],
    },
    {
      path: "charges",
      element: <CommerceLayout />,
      children: [
        {
          path: ":id",
          element: <ChargesCommerce />,
        },
      ],
    },
    {
      path: "point-of-sales",
      element: <PointsOfSaleLayout />,
      children: [
        {
          path: ":posId",
          element: <Checkouts />,
        },
      ],
    },
    {
      path: "fizen-components",
      element: <FizenComponents />,
    },
    {
      path: "*",
      element: <Outlet />,
      children: [
        { path: "expired", element: <Expired /> },
        { path: "website-under-maintenance", element: <UnderMaintenance /> },
        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard" replace />,
        },
        {
          path: "signup",
          element: <SignUp />,
        },
        {
          path: "signin",
          element: <SignIn />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "new-password",
          element: <NewPassword />,
        },
        {
          path: "confirmation",
          element: <Confirmation />,
        },
        {
          path: "active-account",
          element: <ActiveAccount />,
        },
      ],
    },
    {
      path: "/fizen-deeplink-generator",
      element: <IosRedirect />,
    },
  ]);
}

// const SignUp = Loadable(lazy(() => import("pages/signup")));
// const SignIn = Loadable(lazy(() => import("pages/signin")));
// const ResetPassword = Loadable(lazy(() => import("pages/reset-password")));
// const NewPassword = Loadable(lazy(() => import("pages/new-password")));
// const Confirmation = Loadable(lazy(() => import("pages/signup/Confirmation")));
const ActiveAccount = Loadable(lazy(() => import("pages/signin/ActiveAccount")));
const Invoices = Loadable(lazy(() => import("pages/dashboard/invoices")));
const PoS = Loadable(lazy(() => import("pages/dashboard/PoS")));
const Payments = Loadable(lazy(() => import("pages/dashboard/payments")));
const Settings = Loadable(lazy(() => import("pages/dashboard/settings")));
const CryptoSettings = Loadable(lazy(() => import("pages/dashboard/crypto-settings")));
const CreateNewWallet = Loadable(
  lazy(() => import("pages/dashboard/crypto-settings/CreateNewWallet")),
);
const Balances = Loadable(lazy(() => import("pages/dashboard/balances")));
const WithdrawalHistory = Loadable(lazy(() => import("pages/dashboard/balances/history")));
const Checkouts = Loadable(lazy(() => import("pages/dashboard/PoS/[posId]")));

const ChargesCommerce = Loadable(lazy(() => import("pages/commerce/charges")));
const FizenComponents = Loadable(lazy(() => import("pages/@fizen-components/FizenComponents")));
const UnderMaintenance = Loadable(lazy(() => import("pages/UnderMaintenance")));
const PoSForm = Loadable(lazy(() => import("features/pos/form")));
// const RootLayout = Loadable(lazy(() => import("components/RootLayout")));
