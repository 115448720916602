const breakpoints: { values: { [Key: string]: number } } = {
  values: {
    xs: 0,
    xss: 500,
    sm: 600,
    md: 900,
    mdd: 950,
    lg: 1200,
    mac: 1440,
    xl: 1536,
    fhd: 1920,
    qhd: 2560,
  },
};

export default breakpoints;
