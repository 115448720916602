import React, { useState } from "react";
// MUI
import { Box, Link, Stack, Theme, Typography, alpha } from "@mui/material";
// Component
import { ButtonContained, Input } from "@fizen/ui-components";
import { FizenLink } from "components/FizenLink";
import Page from "components/Page";
import Form from "components/form";
// Hooks
import useAuth from "hooks/useAuth";
import { PATH_AUTH } from "routes/path";
// Recoil
import { useRecoilValue } from "recoil";
import { userProfile } from "recoil/atom";
// Utils
import { IntegerInputFormat } from "utils/inputHandler";
// Package
import { yupResolver } from "@hookform/resolvers/yup";
import BasicDialog from "components/dialog/BasicDialog";
import { useLoading } from "hooks/useLoading";
import { useModal } from "hooks/useModal";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { authenticationPageTitles } from "utils/configs";
import { notifyError } from "utils/notifications";
import * as yup from "yup";
import { useCountdown } from "../../hooks/useCountdown";
import { ErrorAPI } from "../../utils/axios/errors";
// import { useLoading } from "hooks/useLoading";
// import { notifyError } from "utils/notifications";

const formModel = {
  email: {
    type: "string",
    label: "Email",
    name: "email",
    sx: {
      width: "100%",
      "& .MuiFilledInput-root": {
        transition: (theme: Theme) =>
          theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:hover": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
          boxShadow: (theme: Theme) => `${alpha(theme.palette.warning.dark, 0.25)} 0 0 0 2px`,
        },
        "&.Mui-focused": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
        },
      },
    },
  },
  password: {
    type: "password",
    label: "Password",
    name: "password",
    sx: {
      width: "100%",
      marginTop: 1.5,
      "& .MuiFilledInput-root": {
        transition: (theme: Theme) =>
          theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:hover": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
          boxShadow: (theme: Theme) => `${alpha(theme.palette.warning.dark, 0.25)} 0 0 0 2px`,
        },
        "&.Mui-focused": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
        },
      },
    },
  },
};

const validationSchema = yup.object().shape({
  email: yup.string().required("This field is required").email("Email is invalid"),
  password: yup.string().required("This field is required"),
});

export interface FormLoginValues {
  email?: string;
  password?: string;
  code?: string;
}

const defaultValues = { email: "", password: "" };

const SignIn = () => {
  const { login, resendEmail } = useAuth();
  const { isAuthenticated, user } = useRecoilValue(userProfile);
  const [loadingResend] = useLoading();
  // State
  const [digitCode, setDigitCode] = useState<string>("");
  const [userInput, setUserInput] = useState<FormLoginValues>({ email: "", password: "" });
  const [backupCode, setBackUpCode] = useState<string>("");
  const [mode, setMode] = useState<string>("2fa");
  const [openResend, onShowResend, onCloseResend] = useModal();
  const [openNotiVeryfy, onShowNotiVerify, onCloseNotiVerify] = useModal();
  //const [openAfterResend, onShowAfterResend, onCloseAfterResend] = useModal();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [emailToSent, setEmailToSent] = useState<string>(""); // if not undefined, sent email is successfully
  const [countdown, , resetCountdown] = useCountdown(60, {
    acceptCountDown: !!emailSent,
    onCountDownEnd: () => {
      setEmailSent(false);
      resetCountdown();
    },
  });
  const form = useForm<FormLoginValues>({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const handleChangeDigitCode = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDigitCode(IntegerInputFormat(e.target.value, 6));
  const handleChangeBackupCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setBackUpCode(value);
    }
  };

  const changeInputMode = (m: string) => () => {
    setMode(m);
    setDigitCode("");
    setBackUpCode("");
    form.clearErrors();
  };

  const handleConfirmCode = async () => {
    try {
      const data = {
        ...userInput,
        authCode: mode === "2fa" ? digitCode : backupCode,
      };
      await login(data);
    } catch (err) {
      if (err instanceof ErrorAPI) {
        if (err.messageCode === "SECURITY.INVALID_OTP") {
          form.setError("code", {
            message: mode === "2fa" ? "Authentication code is invalid" : "Backup code is invalid",
          });
        } else {
          form.setError("code", { message: err.message });
        }
      }
    }
  };

  const onFirstLogin = async (values: FormLoginValues) => {
    try {
      const data = { ...values, email: values.email?.trim() };
      setUserInput(data);
      await login(data);
    } catch (err: any) {
      form.setError("email", { message: err.message }, { shouldFocus: true });
      form.setError("password", { message: err.message });
      setEmailToSent(values.email?.trim() ?? "");
      if (err.messageCode === "AUTH.ACCOUNT_NOT_ACTIVATED") {
        onShowResend();
      }
      if (err.messageCode === "AUTH.MERCHANT_NOT_VERIFIED") {
        onShowNotiVerify();
      }
    }
  };

  const handleResendEmail = async () => {
    try {
      //onLoadingResend();
      await resendEmail({ type: "active-account", email: emailToSent });
      form.clearErrors();
      setEmailSent(true);
      //onCloseResend();
      //onCloseNotiVeryfy(); - wait BE
      //onShowAfterResend();
    } catch (err) {
      if (err instanceof Error) {
        notifyError(err.message);
      }
    } finally {
      //onEndResend();
      setEmailSent(true);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Page title="Sign In | Fizen Payment Gateway">
      {/* <BasicDialog
        open={openAfterResend}
        onClose={onCloseAfterResend}
        title="Send email successfully"
      >
        <Typography variant="text">
          We have sent you an email to activate your account. Please check your email and click the
          link to activate your
        </Typography>
        <div className="flex justify-center mt-5">
          <ButtonContained sx={{ width: 200 }} onClick={onCloseAfterResend}>
            Close
          </ButtonContained>
        </div>
      </BasicDialog> */}
      <BasicDialog
        open={openResend}
        onClose={onCloseResend}
        title="Your account has been temporarily deactivated."
      >
        <Typography component="p" variant="text" align="center">
          We've detected suspicious activities on your account and have temporarily deactivated it
          as a security precaution.
        </Typography>
        <Typography component="p" variant="text" mt={2} align="center">
          If you wish to have it reactivated, please contact
        </Typography>
        <Link sx={{ textDecoration: "none" }} href="mailto:support@fizen.io">
          <Typography component="p" variant="text" align="center">
            support@fizen.io
          </Typography>
        </Link>
        {/* <div className="flex justify-center mt-5">
          <ButtonContained sx={{ width: 200 }} onClick={handleResendEmail} loading={loadingResend}>
            Send email
          </ButtonContained>
        </div> */}
      </BasicDialog>

      <BasicDialog
        open={openNotiVeryfy}
        onClose={onCloseNotiVerify}
        title="Please verify your email address"
      >
        <Typography component="p" variant="text" align="center">
          It seems that your email address has not been verified. Please find the verification email
          in your mailbox and click the link inside to continue.
        </Typography>
        <Typography component="p" variant="text" mt={2} align="center">
          If you did not receive the verification email, please click the button below to resend.
        </Typography>
        <div className="flex justify-center mt-5">
          <ButtonContained onClick={handleResendEmail} loading={loadingResend} disabled={emailSent}>
            {!emailSent ? "Resend" : `Email sent! You can click Resend again in ${countdown}s`}
          </ButtonContained>
        </div>
      </BasicDialog>
      <div className="flex w-full h-screen justify-center items-center flex-col gap-[12px] 2xl:gap-[24px]">
        <Typography sx={{ fontSize: 36, fontWeight: 600, color: "white" }}>
          {authenticationPageTitles}
        </Typography>
        <Box
          sx={{
            boxShadow: "rgb(0 0 0 / 6%) 0px 4px 4px, rgb(0 0 0 / 8%) 0px 8px 18px",
            width: { xs: 9 / 10, md: 650 },
            bgcolor: "rgba(255, 255, 255, 0.3)",
            borderRadius: "9px",
          }}
          py={{ xs: 3, mac: 5 }}
          px={{ xs: 3, lg: 8 }}
        >
          <Typography
            component="p"
            variant="big"
            sx={{ textAlign: "center", mb: 3, color: "text.paper" }}
          >
            Sign in
          </Typography>
          {/*<Divider sx={{ marginBottom: 3, marginTop: 2 }} />*/}
          {/* {form.formState.errors?.afterSubmit && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {t(form.formState.errors?.afterSubmit?.message)}
            </Alert>
          )} */}
          {user && !isAuthenticated ? (
            <Stack spacing={3}>
              {mode === "2fa" ? (
                <Input
                  autoFocus
                  fullWidth
                  label="Enter Google Authentication code"
                  // sx={{ height: 50 }}
                  value={digitCode}
                  onChange={handleChangeDigitCode}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && digitCode && digitCode.length === 6) {
                      handleConfirmCode();
                    }
                  }}
                  error={!!form.formState.errors?.code}
                  helperText={form.formState.errors?.code?.message}
                />
              ) : (
                <Input
                  autoFocus
                  fullWidth
                  label="Enter an one-time use backup code"
                  // sx={{ height: 50 }}
                  value={backupCode}
                  onChange={handleChangeBackupCode}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && backupCode && backupCode.length === 10) {
                      handleConfirmCode();
                    }
                  }}
                  error={!!form.formState.errors?.code}
                  helperText={form.formState.errors?.code?.message}
                />
              )}
              <ButtonContained
                fullWidth
                onClick={handleConfirmCode}
                sx={{ height: 50 }}
                disabled={
                  !(mode === "2fa" && digitCode && digitCode.length === 6) &&
                  !(mode === "backup" && backupCode && backupCode.length === 10)
                }
              >
                Continue
              </ButtonContained>
            </Stack>
          ) : (
            <Form
              form={form}
              formModel={formModel}
              formButton={
                <div className="mt-3">
                  <FizenLink to={PATH_AUTH.resetPassword}>
                    <Typography variant="text" sx={{ mt: 3, color: "text.paper" }}>
                      Forgot password
                    </Typography>
                  </FizenLink>
                  <ButtonContained
                    fullWidth
                    sx={{ marginTop: 2.5, textTransform: "none" }}
                    size="large"
                    type={`submit`}
                    variant="contained"
                    disabled={false}
                  >
                    Sign in
                  </ButtonContained>
                </div>
              }
              // style="dark"
              initialValue={defaultValues}
              validationSchema={validationSchema}
              onFinish={onFirstLogin}
            />
          )}

          <Stack marginTop={3} spacing={1.5}>
            {user && !isAuthenticated ? (
              mode === "2fa" ? (
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="text" sx={{ color: "text.paper" }}>
                    Don't have your phone?
                  </Typography>
                  <Typography
                    variant="text"
                    sx={{ color: "warning.dark", cursor: "pointer" }}
                    onClick={changeInputMode("backup")}
                  >
                    Enter a backup code
                  </Typography>
                </Stack>
              ) : (
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="text" sx={{ color: "text.paper" }}>
                    Use 2FA instead?
                  </Typography>
                  <Typography
                    variant="text"
                    sx={{ color: "warning.dark", cursor: "pointer" }}
                    onClick={changeInputMode("2fa")}
                  >
                    Enter Google Authentication code
                  </Typography>
                </Stack>
              )
            ) : (
              <>
                {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}> */}
                <Typography variant="text" sx={{ color: "text.paper", textAlign: "center" }}>
                  Don't have an account?{" "}
                  <FizenLink to={PATH_AUTH.signUp}>
                    <Typography variant="text" sx={{ color: "warning.dark" }}>
                      Create an account
                    </Typography>
                  </FizenLink>
                </Typography>

                {/* </Stack> */}
                {/*<Stack direction="row" justifyContent="space-between">*/}
                {/*  <Typography variant="text">Forgot your password?</Typography>*/}
                {/*  <FizenLink to={PATH_AUTH.resetPassword}>*/}
                {/*    <Typography variant="text">Reset your password</Typography>*/}
                {/*  </FizenLink>*/}
                {/*</Stack>*/}
              </>
            )}
          </Stack>
        </Box>
      </div>
    </Page>
  );
};

export default SignIn;
