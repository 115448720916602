import { setRecoilExternalState } from "RecoilExternalState";
import axios from "axios";
import { defaultUserProfile, userProfile, userToken } from "recoil/atom";
import { apiMerchantPrefix } from "utils/configs";
import { notifyError } from "utils/notifications";
import { setSession, setSessionInfo } from "utils/sessions";
import { ErrorAPI } from "./errors";

// ----------------------------------------------------------------------

export function handleResponse(res: { data: any }) {
  if (!res.data) {
    return Promise.reject(new Error("Something went wrong"));
  } else {
    return Promise.resolve(res.data);
  }
}

const axiosInstance = axios.create({
  baseURL: apiMerchantPrefix,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.message);
    if (error.response?.data && error.response.data.messageCode === "AUTH.SESSION_NOT_FOUND") {
      notifyError(error.response.data.description);
      setSession(null);
      setSessionInfo(null);
      setRecoilExternalState(userProfile, defaultUserProfile);
      setRecoilExternalState(userToken, undefined);
      window.location.reload();
      return Promise.reject();
    }
    return Promise.reject(
      (error.response &&
        error.response.data &&
        new ErrorAPI(
          error.response.data.description,
          error.response.data.messageCode,
          error.response.data.description,
        )) ||
        error ||
        "Something went wrong",
    );
  },
);

export default axiosInstance;
