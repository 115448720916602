import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";

const ButtonContained = ({ title, children, onClick, disabled, loading, sx, size, ...props }) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      disabled={disabled || loading}
      variant="contained"
      size={size}
      sx={{
        "&:disabled": {
          bgcolor: "text.subBody",
          color: "text.paper",
        },
        ...sx,
      }}
    >
      {loading && (
        <CircularProgress style={{ width: 15, height: 15, color: "white", marginRight: 10 }} />
      )}
      {title ? <Typography>{title}</Typography> : children}
    </Button>
  );
};

export default ButtonContained;
