import MenuIcon from "@mui/icons-material/Menu";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import MImage from "components/@mui-extends/MImage";
import WithMerchantHost from "components/hoc/wrapper/WithMerchant";
import { Outlet } from "react-router-dom";

const listHeaderLink = [
  {
    link: "https://fizen.io",
    title: "Homepage",
  },
  {
    link: "https://wallet.fizen.io",
    title: "Wallet",
  },
  {
    link: "https://pay.fizen.io",
    title: "Fizen Pay",
  },
];

// const discoverLink = [
//   {
//     link: "https://fizen.io/dao/#team",
//     title: "Team",
//   },
//   {
//     link: "https://drive.google.com/drive/u/1/folders/1oN8K7icRAFJiGnolDohFdcLeewoH_H8f",
//     title: "Media Kit",
//   },
// ];

// const appsLink = [
//   {
//     link: "https://apps.apple.com/us/app/fizen-wallet-gamefi-payment/id1621269508",
//     icon: "/assets/images/icons/app-store.svg",
//   },
//   {
//     link: "https://play.google.com/store/apps/details?id=com.fizen.io.wallet",
//     icon: "/assets/images/icons/google-play.svg",
//   },
// ];

const RootLayout = () => {
  return (
    <WithMerchantHost>
      <Box
        sx={{
          position: "relative",
          background: `url("/assets/images/background/background2.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            py: 2,
            px: { xs: 2, lg: 7.5 },
          }}
        >
          <Link href="https://fizen.io/" target="_blank" rel="noreferrer noopener">
            <MImage src="/assets/images/icons/fizen-logo.svg" />
          </Link>
          <Stack
            direction="row"
            alignItems="center"
            spacing={3.75}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ display: { xs: "none", lg: "flex" } }}
          >
            <Stack direction="row" alignItems="center" spacing={3.75}>
              {listHeaderLink.map(({ link, title }) => (
                <Link
                  href={link}
                  target="_blank"
                  rel="noreferrer noopener"
                  underline="none"
                  key={title}
                >
                  <Typography
                    variant="text"
                    sx={{ color: "text.paper", "&:hover": { color: "warning.dark" } }}
                  >
                    {title}
                  </Typography>
                </Link>
              ))}
            </Stack>

            {/*<Stack direction="row" alignItems="center" spacing={3.75}>*/}
            {/*  {discoverLink.map(({ link, title }) => (*/}
            {/*    <Link*/}
            {/*      href={link}*/}
            {/*      target="_blank"*/}
            {/*      rel="noreferrer noopener"*/}
            {/*      underline="none"*/}
            {/*      key={title}*/}
            {/*    >*/}
            {/*      <Typography*/}
            {/*        variant="text"*/}
            {/*        sx={{ color: "text.paper", "&:hover": { color: "warning.dark" } }}*/}
            {/*      >*/}
            {/*        {title}*/}
            {/*      </Typography>*/}
            {/*    </Link>*/}
            {/*  ))}*/}
            {/*</Stack>*/}

            {/*<Stack direction="row" alignItems="center" spacing={3.75}>*/}
            {/*  {appsLink.map(({ link, icon }) => (*/}
            {/*    <Link*/}
            {/*      href={link}*/}
            {/*      target="_blank"*/}
            {/*      rel="noreferrer noopener"*/}
            {/*      underline="none"*/}
            {/*      key={icon}*/}
            {/*    >*/}
            {/*      <MImage src={icon} />*/}
            {/*    </Link>*/}
            {/*  ))}*/}
            {/*</Stack>*/}
          </Stack>
          <MenuIcon sx={{ display: { lg: "none" }, color: "white" }} />
        </Stack>
        <Outlet />
        <Stack
          direction="row"
          spacing={{ xs: 2, lg: 10 }}
          sx={{ position: "absolute", bottom: 20, width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Link
            href="https://fizen.io/terms-of-use"
            target="_blank"
            rel="noreferrer noopener"
            underline="none"
          >
            <Typography
              variant="text"
              sx={{ color: "text.subBody", "&:hover": { color: "warning.dark" } }}
            >
              Terms of Use
            </Typography>
          </Link>
          <Link
            href="https://fizen.io/privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
            underline="none"
          >
            <Typography
              variant="text"
              sx={{ color: "text.subBody", "&:hover": { color: "warning.dark" } }}
            >
              Privacy Policy
            </Typography>
          </Link>
          <Link href="https://fizen.io/" target="_blank" rel="noreferrer noopener" underline="none">
            <Typography
              variant="text"
              sx={{ color: "text.subBody", "&:hover": { color: "warning.dark" } }}
            >
              @ Fizen | Fizen Pay
            </Typography>
          </Link>
        </Stack>
      </Box>
    </WithMerchantHost>
  );
};

export default RootLayout;
