import { intersectionBy, keyBy } from "lodash";
import * as AuthAPI from "pages/signin/__api__";
import { getProfile } from "pages/signin/__api__";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { defaultUserProfile, userProfile, userToken } from "recoil/atom";
import { setSession, setSessionInfo } from "utils/sessions";
import { getChainsInfo } from "../pages/settings/services";
import { FormLoginValues } from "../pages/signin";
import { initialWeb3WithProvider } from "../utils/ethers";
import useAppProviders from "./useAppProviders";

export default function useAuth() {
  const setUserProfile = useSetRecoilState(userProfile);
  const setUserToken = useSetRecoilState(userToken);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { onSetAppProvider } = useAppProviders();

  const login = async (loginInfo: FormLoginValues): Promise<void> => {
    const res = await AuthAPI.login(loginInfo);
    const { merchant, accessToken } = res.data;
    if (!accessToken && merchant.security.is2faEnabled) {
      setUserProfile({
        user: merchant,
        isAuthenticated: false,
        isInitialize: true,
      });
    } else {
      // setUserProfile({
      //   user,
      //   isAuthenticated: true,
      //   isInitialize: true,
      // });
      setUserToken(accessToken);
      setSession(accessToken);
      setSessionInfo(merchant);
      await getMerchantProfile(true);
      const callback = query.get("callback");
      if (callback) {
        navigate(callback, { replace: true });
      } else {
        navigate("/dashboard/payments", { replace: true });
      }
    }
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    setSessionInfo(null);
    setUserProfile(defaultUserProfile);
    setUserToken(undefined);
    window.location.href = window.location.origin + "/signin";
  };

  const resetPassword = async (values: { email: string }): Promise<void> => {
    await AuthAPI.resetPassword(values);
  };

  const changeNewPassword = async (values: {
    forgotPassword: string;
    password: string;
    confirmedPassword: string;
  }) => {
    await AuthAPI.changeNewPassword(values);
  };

  const resendEmail = async (params: {
    type: "forgot-password" | "active-account" | "support-email";
    email: string;
  }) => {
    await AuthAPI.resendEmail(params);
  };

  const checkExpire = async (params: { email: string; code: string }) => {
    await AuthAPI.checkExpire(params);
  };

  const getMerchantProfile = async (initProviders = false) => {
    try {
      const res = await getProfile();
      setUserProfile({
        user: res.data,
        isAuthenticated: true,
        isInitialize: true,
      });
      if (initProviders) {
        if (res.data.currentStep === "WALLET_SETUP_COMPLETED") {
          const chainInfo = await getChainsInfo();
          const wallets = intersectionBy(chainInfo?.data ?? [], res.data?.wallets ?? [], "network");

          const initJsonProvider = wallets.map((wallet) => ({
            rpcUrl: wallet.provider,
            provider: initialWeb3WithProvider(wallet.provider),
            network: wallet.network,
            scanLink: wallet.transactionUrl,
          }));
          const providerData = keyBy(initJsonProvider, "network");
          onSetAppProvider("jsonRpcProvider", providerData);
        }
      }
    } catch (err) {
      setUserProfile({
        user: undefined,
        isAuthenticated: false,
        isInitialize: true,
      });
    }
  };

  return {
    login,
    logout,
    resetPassword,
    changeNewPassword,
    getMerchantProfile,
    resendEmail,
    checkExpire,
  };
}
