import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userProfile } from "recoil/atom";

export function WithSignin({ children }) {
  const location = useLocation();
  const { isAuthenticated, isInitialize } = useRecoilValue(userProfile);
  console.log(location);
  // Authentication check logic
  if (!isAuthenticated && isInitialize) {
    return <Navigate to={`/signin?callback=${location.pathname}${location.search}`} />;
  }
  return children;
}
