import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, TextField } from "@mui/material";
import { isFunction } from "lodash";
import { Controller, DefaultValues, SubmitHandler, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import Input from "../Input/Input";
import { DefaultOptionsProps, FormBaseProps } from "./types";

const Form = <T extends Record<string, any> = Record<string, any>>({
  formModel,
  onFinish,
  initialValue,
  formButton,
  validationSchema,
  form,
}: FormBaseProps<T>) => {
  const privateForm = useForm<T>({
    defaultValues: initialValue as DefaultValues<T>,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const {
    // register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form ?? privateForm;

  const onSubmit: SubmitHandler<T> = (data) => onFinish(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Object.entries(formModel).map(([key, value], idx) => {
        switch (value.type) {
          case "string":
            return (
              <Controller
                key={key}
                name={key as any}
                control={control}
                // defaultValue={initialValue[key]}
                // rules={value.rules}
                render={
                  ({ field }) => (
                    // style === "light" ? (
                    //   <TextField
                    //     {...field}
                    //     error={!!errors[key]}
                    //     helperText={errors[key]?.message}
                    //     label={value.label}
                    //     sx={value.sx}
                    //     autoFocus={idx === 0}
                    //     variant="filled"
                    //   />
                    // ) : (
                    <Input
                      {...field}
                      error={!!errors[key]}
                      helperText={errors[key]?.message}
                      label={value.label}
                      sx={value.sx}
                      autoFocus={idx === 0}
                    />
                  )
                  // )
                }
              />
            );
          case "password":
            return (
              <Controller
                key={key}
                name={key as any}
                control={control}
                // defaultValue={initialValue[key]}
                // rules={value.rules}
                render={
                  ({ field }) => (
                    // style === "light" ? (
                    //   <TextField
                    //     {...field}
                    //     label={value.label}
                    //     sx={value.sx}
                    //     type="password"
                    //     error={!!errors[key]}
                    //     helperText={errors[key]?.message}
                    //     autoFocus={idx === 0}
                    //   />
                    // ) : (
                    <Input
                      {...field}
                      label={value.label}
                      sx={value.sx}
                      type="password"
                      error={!!errors[key]}
                      helperText={errors[key]?.message}
                      autoFocus={idx === 0}
                    />
                  )
                  // )
                }
              />
            );
          case "number":
            return (
              <Controller
                key={key}
                name={key as any}
                control={control}
                // defaultValue={initialValue[key]}
                // rules={value.rules}
                render={({ field: { ref, ...props } }) => {
                  return (
                    // <TextField
                    //     {...field}
                    //     error={!!errors[key]}
                    //     helperText={errors[key]?.message}
                    //     label={value.label}
                    //     sx={value.sx}
                    //     InputProps={{
                    //       inputComponent: NumberInput
                    //     }}
                    // />
                    <NumberFormat
                      {...props}
                      customInput={Input}
                      onValueChange={(v) => {
                        console.log(v);
                        props.onChange(v.floatValue);
                      }}
                      value={props.value}
                      thousandSeparator
                      inputRef={ref}
                      // isNumericString
                      decimalScale={value.decimalScale}
                      prefix={value.prefix}
                      suffix={value.suffix}
                      error={!!errors[key]}
                      helperText={errors[key]?.message}
                      label={value.label}
                      sx={value.sx}
                      autoFocus={idx === 0}
                      allowNegative={value.allowNegative}
                    />
                  );
                }}
              />
            );
          case "select":
            return (
              <Controller
                key={key}
                name={key as any}
                control={control}
                // defaultValue={initialValue[key]}
                // rules={value.rules}
                render={({ field: { onChange, ...props } }) => {
                  if (!value.options) {
                    value.options = [];
                  }
                  const index = value.options.findIndex(
                    (item: DefaultOptionsProps) => item.value === props.value,
                  );
                  const init = value.options[index];
                  return (
                    <Autocomplete
                      sx={value.sx}
                      getOptionLabel={(option) => option.label}
                      options={value.options}
                      renderInput={(params) => <TextField {...params} label={value.label} />}
                      onChange={(e, data) => onChange(data?.value)}
                      value={init}
                    />
                  );
                }}
              />
            );
          case "multiple-select":
            return (
              <Controller
                key={key}
                name={key as any}
                control={control}
                // defaultValue={initialValue[key]}
                // rules={value.rules}
                render={({ field: { onChange, ...props } }) => {
                  if (!value.options) {
                    value.options = [];
                  }
                  return (
                    <Autocomplete
                      sx={value.sx}
                      multiple
                      getOptionLabel={(option) =>
                        value.mappingLabel ? value.mappingLabel[option as any] ?? option : option
                      }
                      options={value.options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={value.label}
                          error={!!errors[key]}
                          helperText={errors[key]?.message}
                        />
                      )}
                      onChange={(e, data) => {
                        if (value.limitTags) {
                          if (data.length <= value.limitTags) {
                            onChange(data);
                          }
                        } else {
                          onChange(data);
                        }
                      }}
                      value={props.value}
                      // defaultValue={props.value}
                    />
                  );
                }}
              />
            );
          default:
            return <></>;
        }
      })}
      {/*<input type="submit" />*/}
      {isFunction(formButton)
        ? formButton({ control, currentValue: watch(), setValue })
        : formButton}
    </form>
  );
};

export default Form;
