import React, { forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
// MUI
import { Box } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { currentTabDashboard } from "../recoil/atom";

interface PageProps {
  title?: string;
  tabTitle?: string;
  children?: React.ReactNode | JSX.Element;
}

const Page = forwardRef<any, PageProps>(({ children, title = "", tabTitle, ...other }, ref) => {
  const setTabDashboard = useSetRecoilState(currentTabDashboard);

  useEffect(() => {
    if (tabTitle) {
      setTabDashboard(tabTitle);
    }
  }, [tabTitle, setTabDashboard]);

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

export default Page;
