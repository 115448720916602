import { ButtonContained, Form, FormModel, Modal, ModalProps } from "@fizen/ui-components";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import * as yup from "yup";
import { hubspotFormId, hubspotPortalId } from "../../utils/configs";
import { notifyError, notifySuccess } from "../../utils/notifications";

const contactSalesForm: FormModel = {
  firstname: {
    type: "string",
    label: "First name",
  },
  lastname: {
    type: "string",
    label: "Last name",
  },
  email: {
    type: "string",
    label: "Email",
  },
  businessWebsite: {
    type: "string",
    label: "Your business website",
  },
  businessDescription: {
    type: "string",
    label: "Your business description",
  },
};

interface ContactSalesFormValue {
  firstname: string;
  lastname: string;
  email: string;
  businessWebsite: string;
  businessDescription: string;
}

const defaultContactSalesValue: ContactSalesFormValue = {
  firstname: "",
  lastname: "",
  email: "",
  businessWebsite: "",
  businessDescription: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().required("This field is required").email("Email is invalid"),
  firstname: yup.string().required("This field is required"),
  lastname: yup.string().required("This field is required"),
  businessWebsite: yup.string().required("This field is required"),
  businessDescription: yup.string().required("This field is required"),
});

function GoMainnetDialog({ onClose }: ModalProps) {
  const sendContactSales = async (values: ContactSalesFormValue) => {
    try {
      console.log(values);
      const data = {
        formId: hubspotFormId,
        portalId: hubspotPortalId,
        data: {
          submittedAt: new Date().getTime(),
          fields: [
            {
              name: "firstname",
              value: values.firstname,
            },
            {
              name: "lastname",
              value: values.lastname,
            },
            {
              name: "email",
              value: values.email,
            },
            {
              name: "your_business_website",
              value: values.businessWebsite,
            },
            {
              name: "business_short_description",
              value: values.businessDescription,
            },
          ],
        },
      };

      await axios.post("https://forwarder.xpsgame.io/hubspot", data);
      notifySuccess("Thank you for your interest. We will contact you soon.");
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        notifyError(err.message);
      }
    }
  };
  return (
    <Modal onClose={onClose}>
      <Typography component="p" sx={{ fontSize: 36, fontWeight: 700 }} align="center">
        Contact Sales
      </Typography>
      <Typography component="p" variant="lead" align="center">
        Get in touch with the Fizen Pay team if you want to get whitelisted or if you have questions
        about the product and integration of crypto payments.
      </Typography>
      <Box mt={1}>
        <Form
          formModel={contactSalesForm}
          onFinish={sendContactSales}
          initialValue={defaultContactSalesValue}
          validationSchema={validationSchema}
          formButton={({ currentValue }) => (
            <ButtonContained
              fullWidth
              type="submit"
              disabled={
                !currentValue.email ||
                !currentValue.firstname ||
                !currentValue.lastname ||
                !currentValue.businessWebsite ||
                !currentValue.businessDescription
              }
              size="large"
              sx={{ mt: 1.5 }}
            >
              Send
            </ButtonContained>
          )}
        />
      </Box>
    </Modal>
  );
}

export default GoMainnetDialog;
