export class ErrorAPI extends Error {
  public messageCode: string | undefined;
  public description: string | undefined;

  constructor(message: string, messageCode?: string, description?: string) {
    super(message);
    this.messageCode = messageCode;
    this.description = description;
  }
}
