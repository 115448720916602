import React, { useEffect, useState } from "react";
// MUI
import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { ButtonContained } from "components/button";
import GppBadIcon from "@mui/icons-material/GppBad";
// Component
import { StepCard, stepsIndex } from "pages/signup/steps";
import { IntegerInputFormat } from "utils/inputHandler";
// API
import * as SignUpService from "pages/signup/__api__";
// Recoil
import { useRecoilValue } from "recoil";
import { userToken } from "recoil/atom";
import { notifyError } from "utils/notifications";
import dayjs from "dayjs";
import { errorsCode } from "utils/constants/errorsCode";
import { useNavigate } from "react-router-dom";

const step = {
  form: 0,
  backup: 1,
};

const GoogleAuthen = ({ currentStep, setCurrentStep }) => {
  const [childStep, setChildStep] = useState(step.form);
  const [digitCode, setDigitCode] = useState("");
  const [authenData, setAuthenData] = useState({
    img: undefined,
    secret: undefined,
  });
  const [loadingSetup2FA, setLoadingSetup2FA] = useState(false);
  const [error, setError] = useState("");
  const [showQRCode, setShowQRCode] = useState(true);
  const navigate = useNavigate();
  const token = useRecoilValue(userToken);

  useEffect(() => {
    const get2FA = async () => {
      try {
        const res = await SignUpService.getGoogleAuthenInfo();
        setAuthenData(res.data);
      } catch (err) {}
    };

    if (token && currentStep === stepsIndex.googleAuthen) {
      get2FA();
    }
  }, [currentStep, token]);

  const handleConfirmCode = async () => {
    try {
      setLoadingSetup2FA(true);
      await SignUpService.setup2FA({ fa_otp_token: digitCode });
      setChildStep(step.backup);
      if (!showQRCode) {
        setShowQRCode(true);
      }
    } catch (err) {
      setError(err.message);
      if (err.messageCode === errorsCode.notEnoughGasForTransaction) {
        navigate("/website-under-maintenance");
      } else {
        setError(err.message);
      }
    } finally {
      setLoadingSetup2FA(false);
    }
  };

  const handleChangeDigitCode = (e) => {
    setDigitCode(IntegerInputFormat(e.target.value, 6));
    if (error) {
      setError("");
    }
  };

  const onDownloadBackupCodes = async () => {
    try {
      const res = await SignUpService.downloadBackupCodes();
      const backupCode = res.data ?? [];
      const fileName = `Fizen Pay_Backup codes_${dayjs().format("DD.MM.YYYY")}`;
      const backupCodeText = backupCode.join("\r\n\n");
      const blob = new Blob([backupCodeText], { type: "application/json" });
      const href = await URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName + ".txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      notifyError(err.message);
    } finally {
    }
  };

  const onToggleQR = () => {
    setShowQRCode((r) => !r);
  };

  return (
    <StepCard
      title="Set up 2-step verification"
      leftCol={
        <Box
          sx={{ mt: { xs: 0.5, mac: 1.5 } }}
          className={`flex-auto flex flex-col justify-between`}
        >
          {childStep === step.form ? (
            <>
              <Stack spacing={1}>
                <Typography variant="text500" sx={{ color: "text.subBody", mb: { xs: 0, mac: 2 } }}>
                  Let's enhance the security for your account by adding an extra layer of
                  verification.
                </Typography>

                <Typography variant="text" sx={{ fontWeight: 700 }}>
                  To set up 2-step verification:
                </Typography>
                <Typography variant="text">
                  1. Install{" "}
                  <Typography variant="text" sx={{ color: "primary.main", fontWeight: 600 }}>
                    Google Authenticator
                  </Typography>
                </Typography>
                <Typography variant="text">2. Scan the QR code</Typography>
                <Typography variant="text">3. Enter the code from Authenticator below</Typography>
              </Stack>

              <Stack spacing={3} sx={{ mt: { xs: 1, md: 0 } }}>
                <Box sx={{ position: "relative" }}>
                  <TextField
                    autoFocus
                    fullWidth
                    label="Enter Google Authenticator code"
                    sx={{ height: 50 }}
                    value={digitCode}
                    onChange={handleChangeDigitCode}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && digitCode && digitCode.length === 6) {
                        handleConfirmCode();
                      }
                    }}
                    error={!!error}
                  />
                  {error && (
                    <Stack
                      alignItems="center"
                      direction="row"
                      sx={{ position: "absolute", top: 16, right: 10 }}
                    >
                      <GppBadIcon sx={{ color: "error.main" }} />
                      <Typography variant="smallText" sx={{ color: "error.main" }}>
                        {error}
                      </Typography>
                    </Stack>
                  )}
                </Box>
                <ButtonContained
                  fullWidth
                  onClick={handleConfirmCode}
                  sx={{ height: 50 }}
                  disabled={!(digitCode && digitCode.length === 6)}
                >
                  Continue
                </ButtonContained>
              </Stack>
            </>
          ) : (
            <>
              <Stack spacing={2}>
                <Typography variant="text">
                  Nice work. You've successfully enabled 2-step verification. The next time you sign
                  in, you'll be prompted for a code.
                </Typography>
                <Typography variant="text">
                  If you ever lose access to your 2-step verification device, you can use a backup
                  code to log in. We highly recommend downloading your backup codes before
                  continuing.
                  {/*<HelpIcon sx={{ fontSize: 20 }} />*/}
                </Typography>
              </Stack>

              <Stack spacing={1.5} sx={{ mt: { xs: 3, lg: 3 } }}>
                <ButtonContained
                  fullWidth
                  sx={{ height: 50 }}
                  onClick={() => setCurrentStep(stepsIndex.setupWallet)}
                  loading={loadingSetup2FA}
                >
                  Continue
                </ButtonContained>
                <Button
                  fullWidth
                  sx={{ height: 50, color: "text.primary" }}
                  onClick={onDownloadBackupCodes}
                >
                  Download one-time use backup codes
                </Button>
              </Stack>
            </>
          )}
        </Box>
      }
      rightCol={
        <Stack justifyContent="center" alignItems="center">
          {authenData.img ? (
            <Box sx={{ height: { xs: 300, lg: 260, mac: 398 } }}>
              <Box
                sx={{
                  backgroundColor: "transparent",
                  width: { xs: 300, lg: 260, mac: 300 },
                  height: "100%",
                  perspective: 1000,
                  // "&:hover .flip-card-inner": {
                  //   transform: "rotateY(180deg)",
                  // },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    transition: "transform 1.6s",
                    transformStyle: "preserve-3d",
                    transform: showQRCode ? "rotateY(0)" : "rotateY(180deg)",
                    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  }}
                  className="flip-card-inner"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      WebkitBackfaceVisibility: "hidden",
                      backfaceVisibility: "hidden",
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        childStep === step.form
                          ? authenData.img
                          : "/assets/images/statics/settings.png"
                      }
                      alt=""
                      sx={{ height: { xs: "100%", mac: "80%" } }}
                    />
                    {childStep === step.form && (
                      <Typography
                        component="p"
                        variant="smallText"
                        sx={{ textAlign: "center", width: "100%", mt: { xs: 1, mac: 3 } }}
                      >
                        Can't scan?{" "}
                        <Typography
                          variant="smallText"
                          sx={{ color: "primary.main", fontWeight: 600, cursor: "pointer" }}
                          onClick={onToggleQR}
                        >
                          Click here.
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      WebkitBackfaceVisibility: "hidden",
                      backfaceVisibility: "hidden",
                      transform: "rotateY(180deg)",
                    }}
                    spacing={3}
                  >
                    {childStep === step.form && (
                      <>
                        <Stack spacing={0.2} alignItems="center" mt={1}>
                          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                            Enter this code:
                          </Typography>
                          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                            {authenData.secret}
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                            to your Google Authenticator
                          </Typography>
                        </Stack>
                        <Typography
                          component="p"
                          variant="smallText"
                          sx={{ textAlign: "center", width: "100%", mt: 3 }}
                        >
                          Or{" "}
                          <Typography
                            variant="smallText"
                            sx={{ color: "primary.main", fontWeight: 600, cursor: "pointer" }}
                            onClick={onToggleQR}
                          >
                            Scan QR Code
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Box>
          ) : (
            <CircularProgress />
          )}
        </Stack>
      }
    />
  );
};

export default GoogleAuthen;
