import { Typography } from "@mui/material";
import React from "react";
import { GradientTextProps } from "./types";

const GradientText: React.FC<GradientTextProps> = ({
  startColor = "#903AFD",
  endColor = "#492CFF",
  children,
  sx,
  ...other
}) => {
  return (
    <Typography
      {...other}
      sx={{
        background: `-webkit-linear-gradient(${startColor}, ${endColor})`,
        WebkitBackgroundClip: `text`,
        WebkitTextFillColor: `transparent`,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default GradientText;
