import axiosIntegration from "utils/axios/intergration";
import axios from "utils/axios/merchant";

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosIntegration.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
    delete axiosIntegration.defaults.headers.common.Authorization;
  }
};

const setSessionInfo = (data: any) => {
  if (data) {
    localStorage.setItem("userProfile", JSON.stringify(data));
  } else {
    localStorage.removeItem("userProfile");
  }
};

export { setSession, setSessionInfo };
