import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Button, Container, Typography } from "@mui/material";
// components
import MImage from "components/@mui-extends/MImage";
import Page from "components/Page";
import { PATH_AUTH } from "routes/path";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Expired() {
  return (
    <RootStyle title="Expired">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography paragraph sx={{ fontSize: 36, color: "violet.main", fontWeight: 900 }}>
            Fizen Pay
          </Typography>

          <div className="flex flex-col items-center gap-[40px] mt-14">
            <div className="flex flex-col items-center gap-[24px]">
              <MImage
                src="/assets/images/statics/expired.png"
                sx={{ maxWidth: 120, maxHeight: 120 }}
              />
              <Typography variant="text" sx={{ color: "text.secondary", fontWeight: 600 }}>
                It seems the link is already expired.
              </Typography>
            </div>
            <Button
              to={PATH_AUTH.signIn}
              size="large"
              variant="contained"
              component={RouterLink}
              sx={{ maxWidth: 350, width: "100%" }}
            >
              Back to sign in
            </Button>
          </div>
        </Box>
      </Container>
    </RootStyle>
  );
}
