import { atom } from "recoil";

export const seedPhraseAtom = atom<string>({
  key: "seedPhrase",
  default: "",
});

export const seedConfirmTextAtom = atom<string>({
  key: "seedConfirmText",
  default: "",
});
