import { SelectChangeEvent } from "@mui/material";
import dayjs from "dayjs";
import { useLoading } from "hooks/useLoading";
import * as DashboardServices from "pages/dashboard/__api__";
import { useState } from "react";
import { notifyError } from "../utils/notifications";

const utc = require("dayjs/plugin/utc");

dayjs.extend(utc);

export type TransactionType = "ALL" | "PRODUCT_CHECKOUT" | "INVOICE";

const timeValue = {
  allTime: 1,
  yearToDate: 2,
  lastMonth: 3,
  custom: 4,
};

export const timeOptions = [
  { label: "All time", value: timeValue.allTime },
  { label: "Year to date", value: timeValue.yearToDate },
  { label: "Last month", value: timeValue.lastMonth },
  { label: "Custom", value: timeValue.custom },
];

export const transactionTypes = [
  { label: "All transactions", value: "ALL" },
  // { label: "Checkouts", value: "PRODUCT_CHECKOUT" },
  // { label: "All outflows", value: 3 },
  { label: "Invoices", value: "INVOICE" },
  // { label: "Refund", value: 5 },
  // { label: "Withdrawals", value: 6 },
];

export function useGenerateReports() {
  const [loading, onLoading, onEnded] = useLoading();

  const [time, setTime] = useState<number>(timeValue.allTime); // Default all time
  const [type, setType] = useState<TransactionType>("ALL"); // Default all transactions
  const [value, setValue] = useState<(Date | null)[]>([
    new Date(new Date().setHours(0, 0, 0, 0)),
    new Date(),
  ]);
  const isChooseCustom = time === timeOptions[3].value;

  const onChangeTime = (e: SelectChangeEvent<any>) => {
    setTime(e.target.value);
  };

  const onChangeType = (e: SelectChangeEvent<any>) => {
    setType(e.target.value);
  };
  /* eslint-disable no-useless-concat */
  const JsonToCSV = (csvData: string) => {
    let fileName = `Fizen Pay_Reports_${dayjs().format("DD.MM.YYYY")}`;

    //Initialize file format you want csv or xls
    let uri = "data:text/csv;charset=utf-8," + escape(csvData);
    let link = document.createElement("a");
    link.href = uri;
    link.style.visibility = "hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };
  /* eslint-enable no-useless-concat */

  const onGenerateReport = async () => {
    try {
      onLoading();
      let reportData;
      if (time === timeValue.allTime) {
        reportData = {
          startTime: 1,
          endTime: dayjs().unix() * 1000, // convert to milliseconds
          transactionType: type,
        };
      } else if (time === timeValue.yearToDate) {
        reportData = {
          startTime: dayjs().startOf("year").unix() * 1000,
          endTime: dayjs().unix() * 1000,
          transactionType: type,
        };
      } else if (time === timeValue.lastMonth) {
        const lastMonth = dayjs().startOf("month");
        reportData = {
          startTime: lastMonth.subtract(1, "month").unix() * 1000,
          endTime: lastMonth.unix() * 1000,
          transactionType: type,
        };
      } else if (time === timeValue.custom) {
        if (value[0] && value[1]) {
          reportData = {
            startTime: dayjs(value[0]).unix() * 1000,
            endTime:
              dayjs(value[1])
                .set("hour", 24)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .unix() * 1000,
            transactionType: type,
          };
        }
      }

      if (reportData) {
        const res = await DashboardServices.generateReport(reportData);
        JsonToCSV(res);
      }
    } catch (err) {
      // See: https://www.typescriptlang.org/tsconfig#useUnknownInCatchVariables
      if (err instanceof Error) {
        console.log(err.message);
        notifyError(err.message);
      }
    } finally {
      onEnded();
    }
  };

  return {
    time,
    type,
    onChangeTime,
    onChangeType,
    value,
    setValue,
    isChooseCustom,
    onGenerateReport,

    loading,
  };
}
