import { Typography } from "@mui/material";

const NoResults = () => {
  return (
    <div className="flex justify-center items-center">
      <Typography variant="body1">No results</Typography>
    </div>
  );
};

export default NoResults;
