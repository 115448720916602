export const saveToLocalStorage = (key: string, data: any): void => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) as string) : undefined;
};

export const getStringFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};
