import ercAbi from "blockchains/abi/erc.json";
import { ethers, utils } from "ethers";
import { networkConfig } from "blockchains/configs";
import { BigNumber } from "bignumber.js";
import { MAX_ALLOWANCE_UNIT } from "utils/constants/cryptos";

/*
 * WARNING: This file will be replaced by the one in the repo, do not use it directly
 * See blockchains/utils/*
 * */

function initialWeb3() {
  return new ethers.providers.Web3Provider(window.ethereum, "any");
}

/**
 * @deprecated
 * WARNING: This function will be deprecated and removed in the future
 * */
function initialWeb3WithProvider(rpc) {
  return new ethers.providers.JsonRpcProvider(rpc);
}

// WARNING: This function will be deprecated and removed in the future
async function changeChain(networkName) {
  const params = [
    {
      chainId: networkConfig[networkName]?.hex,
      chainName: networkConfig[networkName]?.name,
      nativeCurrency: {
        name: networkConfig[networkName]?.nativeCurrency?.name,
        symbol: networkConfig[networkName]?.nativeCurrency?.symbol,
        decimals: networkConfig[networkName]?.nativeCurrency?.decimals,
      },
      rpcUrls: networkConfig[networkName]?.rpcUrls,
      blockExplorerUrls: networkConfig[networkName]?.blockExplorerUrls,
    },
  ];

  try {
    return await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: networkConfig[networkName]?.hex }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        return await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params,
        });
      } catch (addError) {
        throw new Error(addError.message);
      }
    } else {
      // console.log(switchError);
      throw new Error(switchError.message);
    }
  }
}

// WARNING: This function will be deprecated and removed in the future
async function allowance(data) {
  const { tokenAddress, walletAddress, merchantContract, amount, customProvider } = data;
  if (!tokenAddress || !walletAddress || !merchantContract) {
    return {
      isAllowance: false,
      allowanceBalanceInBN: new BigNumber(0),
      allowanceBalance: "0",
    };
  }
  const provider = customProvider ?? initialWeb3();
  const contract = new ethers.Contract(tokenAddress, ercAbi, provider);
  const allowanceBalance = await contract.allowance(walletAddress, merchantContract);
  const isAllowance = new BigNumber(allowanceBalance.toString()).gte(new BigNumber(amount));

  return {
    isAllowance,
    allowanceBalanceInBN: new BigNumber(allowanceBalance.toString()),
    allowanceBalance: allowanceBalance.toString(),
  };
}

// WARNING: This function will be deprecated and removed in the future
async function approve(data, onConfirm, callback) {
  const { tokenAddress, merchantContract, amountToApprove, customProvider } = data;
  const provider = customProvider ?? initialWeb3();
  const contract = new ethers.Contract(tokenAddress, ercAbi, provider.getSigner());
  const transaction = await contract.approve(
    merchantContract,
    amountToApprove ?? MAX_ALLOWANCE_UNIT,
  );
  onConfirm && onConfirm();
  await transaction.wait();
  callback && callback();
}

// WARNING: This function will be deprecated and removed in the future
async function cryptoBalanceByEthers(
  { userAddress, tokenName, tokenAddress, rpc, cmcId, decimal },
  appProvider,
) {
  let provider;
  if (appProvider) {
    provider = appProvider;
  } else {
    if (rpc) {
      provider = initialWeb3WithProvider(rpc);
    } else {
      provider = initialWeb3();
    }
  }

  const contract = new ethers.Contract(tokenAddress, ercAbi, provider);
  const balance = await contract.balanceOf(userAddress);

  return {
    name: tokenName,
    balance: utils.formatUnits(balance, decimal),
    cmcId: cmcId,
    address: tokenAddress,
  };
}

// WARNING: This function will be deprecated and removed in the future
async function nativeBalanceByEthers({ userAddress, tokenName, rpc, cmcId }, appProvider) {
  let provider;
  if (appProvider) {
    provider = appProvider;
  } else {
    if (rpc) {
      provider = initialWeb3WithProvider(rpc);
    } else {
      provider = initialWeb3();
    }
  }
  const balance = await provider.getBalance(userAddress);
  return {
    name: tokenName,
    balance: utils.formatEther(balance),
    cmcId: cmcId,
  };
}

// WARNING: This function will be deprecated and removed in the future
async function connectToMetaMask() {
  if (window.ethereum) {
    const provider = initialWeb3();
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    return address;
  }

  return;
}

export {
  cryptoBalanceByEthers,
  nativeBalanceByEthers,
  connectToMetaMask,
  initialWeb3,
  changeChain,
  allowance,
  approve,
  initialWeb3WithProvider,
};
