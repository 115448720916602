import AccountSetup from "pages/signup/AccountSetup";
import { useRecoilValue } from "recoil";
import { userProfile } from "recoil/atom";
import Loading from "components/Loading";
import { stepsIndex } from "pages/signup/steps";

export function WithAccountSetup({ children }) {
  const { user } = useRecoilValue(userProfile);

  if (!user) {
    return <Loading />;
  }

  return user.currentStep === stepsIndex.acceptCrypto ? children : <AccountSetup />;
}
