import React, { useMemo } from "react";
// MUI
import { CssBaseline, ThemeOptions } from "@mui/material";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
// hooks
import { useSettings } from "./ThemeSettings";
//
import breakpoints from "./breakpoint";
import palette from "./colors";
import componentsOverride from "./overrides";
import typography from "./typography";

// ----------------------------------------------------------------------

const ThemeConfig: React.FC = ({ children }) => {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === "light";

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, mode: "light" } : { ...palette.dark, mode: "dark" },
      // shape,
      typography,
      breakpoints,
      direction: themeDirection,
      // shadows: isLight ? shadows.light : shadows.dark,
      // customShadows: isLight ? customShadows.light : customShadows.dark
    }),
    [isLight, themeDirection],
  );

  const theme = createTheme(themeOptions as unknown as ThemeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
