import { SettingsProvider } from "@fizen/ui-components";
import { Web3ReactProvider } from "@web3-react/core";
import { RecoilExternalState } from "RecoilExternalState";
import { BlockChainProvider } from "contexts/BlockchainProvider";
import { CollapseDrawerProvider } from "contexts/CollapseDrawerContext";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./pages";
// i18n
import "locales/i18n";
// styles
import { connectors } from "blockchains/connectors";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "styles/common.css";
import "styles/tailwind.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

console.log(
  "%c\n" +
    "███████╗██╗███████╗███████╗███╗   ██╗\n" +
    "██╔════╝██║╚══███╔╝██╔════╝████╗  ██║\n" +
    "█████╗  ██║  ███╔╝ █████╗  ██╔██╗ ██║\n" +
    "██╔══╝  ██║ ███╔╝  ██╔══╝  ██║╚██╗██║\n" +
    "██║     ██║███████╗███████╗██║ ╚████║\n" +
    "╚═╝     ╚═╝╚══════╝╚══════╝╚═╝  ╚═══╝\n" +
    "                                     \nFizen Pay - Crypto Payment Gateway",
  "color: #903AFD;font-weight:bold;",
);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <RecoilRoot>
          <Web3ReactProvider connectors={connectors}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BlockChainProvider>
                  <App />
                </BlockChainProvider>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </Web3ReactProvider>
          <RecoilExternalState />
        </RecoilRoot>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorkerRegistration.unregister();
