import React, { useState } from "react";
// Component
import Page from "components/Page";
// MUI
import { Box, Stack, Typography } from "@mui/material";
// Package
import { useLocation } from "react-router-dom";
import MImage from "components/@mui-extends/MImage";
import { ButtonContained } from "components/button";
import { FizenLink } from "components/FizenLink";
// Hook
import { useCountdown } from "hooks/useCountdown";
import useAuth from "hooks/useAuth";

const Confirmation = () => {
  const { resendEmail } = useAuth();
  const location = useLocation();
  const [emailSent, setEmailSent] = useState(false);
  const [countdown, , resetCountdown] = useCountdown(60, {
    acceptCountDown: !!emailSent,
    onCountDownEnd: () => {
      setEmailSent(false);
      resetCountdown();
    },
  });

  const onResendEmail = async () => {
    try {
      if (location.state?.email) {
        await resendEmail({ type: "active-account", email: location.state?.email });
        setEmailSent(true);
      }
    } catch (err) {}
  };

  return (
    <Page title="Sign Up | Fizen Payment Gateway">
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "relative",
          background: `url("/assets/images/background/background2.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            boxShadow: "rgb(0 0 0 / 6%) 0px 4px 4px, rgb(0 0 0 / 8%) 0px 8px 18px",
            width: { xs: 9 / 10, md: 550 },
            bgcolor: "rgba(255, 255, 255, 0.3)",
            borderRadius: "9px",
          }}
          py={5}
          px={8}
        >
          <div className="flex flex-col justify-center items-center w-full gap-8">
            <MImage src="/assets/images/statics/email.svg" />
            <div className="flex flex-col gap-2 items-center">
              <Typography variant="big" sx={{ color: "text.paper" }}>
                Thank you for signing up
              </Typography>
              <Typography
                component="p"
                variant="text"
                sx={{ color: "text.paper", textAlign: "center" }}
              >
                A verification email has been sent to
              </Typography>
              <Typography
                component="p"
                variant="text"
                sx={{ color: "warning.dark", textAlign: "center", fontWeight: 700 }}
              >
                {location.state?.email || ""}
              </Typography>
            </div>
            <Typography
              component="p"
              variant="text"
              sx={{ color: "text.paper", textAlign: "center" }}
            >
              Please click the link inside it to continue
            </Typography>

            <FizenLink to="/signin" sx={{ width: "100%" }}>
              <ButtonContained fullWidth>Back to Sign in</ButtonContained>
            </FizenLink>

            <Typography
              component="p"
              align="center"
              variant="text"
              sx={{ color: "text.paper", mt: 1 }}
            >
              {emailSent ? (
                `Email sent! You can click Resend again in ${countdown}s`
              ) : (
                <>
                  Didn’t receive the email?{" "}
                  <Typography
                    component="span"
                    variant="text"
                    sx={{ color: "warning.dark", cursor: "pointer" }}
                    onClick={onResendEmail}
                  >
                    Resend
                  </Typography>
                </>
              )}
            </Typography>
          </div>
        </Box>
      </Stack>
    </Page>
  );
};

export default Confirmation;
