export function IntegerInputFormat(numberString: string, length: number): string {
  if (numberString.length === 0) return "";
  numberString = numberString?.replace(/[\uff01-\uff6e\uff61]/g, function (ch) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });
  let charCode = numberString.charCodeAt(numberString.length - 1);
  if (charCode === 12290 || charCode === 129) {
    numberString = numberString.replace(/.$/, ".");
  }
  numberString = numberString.replace(/[^0-9]/g, "");
  numberString = numberString.replace(".", "");

  if (length && numberString.length > length) {
    numberString = numberString.substring(0, length);
  }

  return numberString;
}

export function formatInputNumber(numberString: string, decimal = 2, defaultValue: string): string {
  numberString = numberString?.replace(/[\uff01-\uff6e\uff61]/g, function (ch) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });
  let charCode = numberString.charCodeAt(numberString.length - 1);
  if (charCode === 12290 || charCode === 129) {
    numberString.replace(/.$/, ".");
  }
  if (decimal === 0) {
    numberString = numberString.replace(/[^0-9]/g, "");
  } else {
    numberString = numberString.replace(/[^0-9.]/g, "");
  }
  if (numberString.length === 0) return defaultValue;
  let index = numberString.indexOf(".");
  if (index > 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = parseInt(numberString.slice(0, index));
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  } else if (index === 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = 0;
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  }
  return numberString;
}
