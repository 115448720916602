import useAppProviders from "hooks/useAppProviders";
import { intersectionBy, keyBy } from "lodash";
import { getChainsInfo } from "pages/settings/services";
import { getProfile } from "pages/signin/__api__";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userProfile, userToken } from "recoil/atom";
import { initialWeb3WithProvider } from "utils/ethers";
import { getStringFromLocalStorage } from "utils/localStorage";
import { setSession } from "utils/sessions";

const AuthProvider = ({ children }: React.PropsWithChildren<any>) => {
  const setUserProfile = useSetRecoilState(userProfile);
  const setUserToken = useSetRecoilState(userToken);
  const { onSetAppProvider } = useAppProviders();
  const location = useLocation();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getStringFromLocalStorage("accessToken");

        if (accessToken) {
          setSession(accessToken);
          const res = await getProfile();
          if (res.data.currentStep === "WALLET_SETUP_COMPLETED") {
            const chainInfo = await getChainsInfo();
            const wallets = intersectionBy(
              chainInfo?.data ?? [],
              res.data?.wallets ?? [],
              "network",
            );

            const initJsonProvider = wallets.map((wallet) => ({
              rpcUrl: wallet.provider,
              provider: initialWeb3WithProvider(wallet.provider),
              network: wallet.network,
              scanLink: wallet.transactionUrl,
            }));
            const providerData = keyBy(initJsonProvider, "network");
            onSetAppProvider("jsonRpcProvider", providerData);
          }

          setUserProfile({
            user: res.data,
            isAuthenticated: true,
            isInitialize: true,
          });
          setUserToken(accessToken);
        } else {
          setUserProfile({
            user: undefined,
            isAuthenticated: false,
            isInitialize: true,
          });
        }
      } catch (err) {
        setUserProfile({
          user: undefined,
          isAuthenticated: false,
          isInitialize: true,
        });
      }
    };

    if (location.pathname.indexOf("commerce") === -1) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
