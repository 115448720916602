import { Network } from "blockchains/configs";
import axios, { handleResponse } from "utils/axios/merchant";
import axiosPublic from "utils/axios/public";

const withdrawToken = async (data: { tokenAddress: string; network: Network }) => {
  return axios({
    method: "post",
    url: "/merchants/withdraw-token",
    data,
  }).then(handleResponse);
};

const withdrawalHistory = async (tokenAddress: string, network: Network) => {
  return axios
    .get(`/withdrawal-histories?tokenAddress=${tokenAddress}&network=${network}`)
    .then(handleResponse);
};

const getLocalExchangeRate = async () => {
  return axiosPublic.get(`/pricings/local-currency-rates`).then(handleResponse);
};

const getCryptoExchangeRate = async () => {
  return axiosPublic.get(`/pricings/crypto-prices`).then(handleResponse);
};

const getEstimateToken = async ({
  tokenAddress,
  network,
}: {
  tokenAddress: string;
  network: Network;
}) => {
  return axios
    .post(`/merchants/estimate-withdraw-token`, { tokenAddress, network })
    .then(handleResponse);
};

const getWithdrawalInformation = async (id: string) => {
  return axios.get(`/withdrawal-histories/${id}`).then(handleResponse);
};

export {
  withdrawToken,
  withdrawalHistory,
  getLocalExchangeRate,
  getCryptoExchangeRate,
  getEstimateToken,
  getWithdrawalInformation,
};
