// MUI
import { Box, Link, Stack, Typography } from "@mui/material";
// Hooks
import { useState } from "react";
// Recoil
import { useRecoilValue } from "recoil";
import { userProfile } from "recoil/atom";

import { ConfigStepRender, steps, stepsIndex, stepsMapping } from "pages/signup/steps";
import { commonIcons } from "utils/constants/icons";
import MImage from "components/@mui-extends/MImage";

// const QontoConnector = styled(StepConnector)(({ theme, color }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: "calc(-50% + 16px)",
//     right: "calc(50% + 16px)",
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: color,
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: color,
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }));

// const QontoStepIconRoot = styled("div")(({ theme, ownerState, color }) => ({
//   color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
//   display: "flex",
//   height: 22,
//   alignItems: "center",
//   ...(ownerState.active && {
//     color: color,
//   }),
//   "& .QontoStepIcon-completedIcon": {
//     color: color,
//     zIndex: 1,
//     fontSize: 18,
//   },
//   "& .QontoStepIcon-circle": {
//     width: 8,
//     height: 8,
//     borderRadius: "50%",
//     backgroundColor: "currentColor",
//   },
// }));

// function QontoStepIcon(props) {
//   const { active, completed, className } = props;
//   const { themeColor } = useSettings();
//   return (
//     <QontoStepIconRoot
//       ownerState={{ active }}
//       color={SetColor(themeColor)?.main}
//       className={className}
//     >
//       {completed ? (
//         <Check className="QontoStepIcon-completedIcon" />
//       ) : (
//         <div className="QontoStepIcon-circle" />
//       )}
//     </QontoStepIconRoot>
//   );
// }

export default function AccountSetup() {
  const { user } = useRecoilValue(userProfile);
  const [currentStep, setCurrentStep] = useState(
    user.approvedStatus !== "APPROVED" ? stepsIndex.verifyEmail : user.currentStep,
  );

  return (
    <Box
      sx={{
        bgcolor: "background.neutral",
        background: `url("/assets/images/background/background2.png")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="flex flex-col justify-center items-center min-h-screen relative"
    >
      {/*<Stepper*/}
      {/*  sx={{ width: "60%" }}*/}
      {/*  alternativeLabel*/}
      {/*  activeStep={currentStep}*/}
      {/*  connector={<QontoConnector color={SetColor(themeColor)?.main} />}*/}
      {/*>*/}
      {/*  {steps.map((label) => (*/}
      {/*    <Step key={label}>*/}
      {/*      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>*/}
      {/*    </Step>*/}
      {/*  ))}*/}
      {/*</Stepper>*/}
      {/*<Divider />*/}
      <Typography sx={{ fontSize: 36, fontWeight: 600, color: "white", mb: 3 }}>
        Fizen Pay
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: { xs: "95%", md: "80%", lg: "60%", xl: "50%" } }}
      >
        {steps.map((label, idx) => (
          <Stack
            key={label}
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            justifyContent={{ md: "center" }}
            alignItems="center"
            sx={{
              bgcolor: currentStep === stepsMapping[label] ? "background.paper" : "#EFF1F4",
              width: 1 / 4,
              height: { xs: 100, md: 60 },
              borderTopLeftRadius: idx === 0 ? 12 : 0,
              borderTopRightRadius: idx === steps.length - 1 ? 12 : 0,
            }}
          >
            {currentStep > stepsMapping[label] && <MImage src={commonIcons.checkedPurple} />}
            {currentStep <= stepsMapping[label] && <MImage src={commonIcons.unchecked} />}
            <Typography
              variant="text"
              align="center"
              sx={{
                fontWeight: 600,
                color:
                  currentStep < stepsMapping[label]
                    ? "text.subBody"
                    : currentStep === stepsMapping[label]
                    ? "primary.main"
                    : "text.primary",
              }}
            >
              {label}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <ConfigStepRender currentStep={currentStep} setCurrentStep={setCurrentStep} />
      <Stack
        direction="row"
        spacing={{ xs: 2, lg: 10 }}
        sx={{ position: { lg: "absolute" }, bottom: 20, width: "100%", my: { xs: 2, lg: 0 } }}
        justifyContent="center"
        alignItems="center"
      >
        <Link
          href="https://fizen.io/terms-of-use"
          target="_blank"
          rel="noreferrer noopener"
          underline="none"
        >
          <Typography
            variant="text"
            sx={{ color: "text.subBody", "&:hover": { color: "warning.dark" } }}
          >
            Terms of Use
          </Typography>
        </Link>
        <Link
          href="https://fizen.io/privacy-policy"
          target="_blank"
          rel="noreferrer noopener"
          underline="none"
        >
          <Typography
            variant="text"
            sx={{ color: "text.subBody", "&:hover": { color: "warning.dark" } }}
          >
            Privacy Policy
          </Typography>
        </Link>
        <Link href="https://fizen.io/" target="_blank" rel="noreferrer noopener" underline="none">
          <Typography
            variant="text"
            sx={{ color: "text.subBody", "&:hover": { color: "warning.dark" } }}
          >
            @ Fizen | Fizen Pay
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
}
