export const apiMerchantPrefix = process.env.REACT_APP_API_ENDPOINTS + `/api/web-app-merchant/v1`;
export const apiPublicPrefix = process.env.REACT_APP_API_ENDPOINTS + `/api/web-app-public/v1`;
export const apiIntegrationPrefix = process.env.REACT_APP_API_ENDPOINTS + `/api/integration/v1`;
export const clientEndpoint = process.env.REACT_APP_CLIENT_ENDPOINT;

// Kima network
export const apiKimaNetworkNodeProvider =
  process.env.REACT_APP_KIMA_NETWORK_NODE_PROVIDER_ENDPOINT || "https://api-testnet.kima.finance";
export const apiKimaNetworkFeeUrl =
  process.env.REACT_APP_KIMA_NETWORK_FEE_ENDPOINT || "https://fee.kima.finance";
export const apiKimaNetworkBackendUrl =
  process.env.REACT_APP_KIMA_NETWORK_BACKEND_ENDPOINT || apiPublicPrefix;

export const googleRecaptchaKey =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ?? "6LfJn3AeAAAAALbYq8VKRb64xHPioNiDCIsAlyAP";

export const cryptoImagePrefix =
  process.env.REACT_APP_COINGECK_CRYPTO_IMAGE ??
  "https://s2.coinmarketcap.com/static/img/coins/64x64";

export const paymentUrl = process.env.REACT_APP_PAYMENT_URL;

export const isTestnet = process.env.REACT_APP_ENVIRONMENT === "testnet";

export const authenticationPageTitles =
  process.env.REACT_APP_ENVIRONMENT === "testnet"
    ? "Fizen Pay - Test Mode"
    : "Fizen Pay - Merchant Portal";

export const hubspotFormId =
  process.env.REACT_APP_HUBSPOT_FORM_ID ?? "32795651-6f02-4057-9a18-5e7e713959d3";
export const hubspotPortalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID ?? "25891453";
