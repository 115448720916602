import React, { useEffect, useMemo, useRef, useState } from "react";
// MUI
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
// Component
import { ButtonContained } from "components/button";
import { StepCard, stepsIndex } from "pages/signup/steps";
// Package
import { ethers } from "ethers";
import { difference, isEqual, shuffle } from "lodash";
// Recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { seedConfirmTextAtom, seedPhraseAtom } from "pages/signup/atom/SetupWallet";
// Hook
import { useChainsSetting } from "hooks/useChains";
// API
import * as SignUpService from "pages/signup/__api__";
import { getProfile } from "pages/signin/__api__";
import MImage from "components/@mui-extends/MImage";
import { isNoDuplicate } from "utils/array";
import MIconButton from "components/@mui-extends/MIconButton";
import { ArrowBack } from "@mui/icons-material";
import { commonIcons } from "utils/constants/icons";
import { isTestnet } from "utils/configs";

const bip39 = require("bip39");

const step = {
  intro: 0,
  intro2: 1,
  seedPhrase: 2,
  seedConfirm: 3,
};

const BoxWord = ({ word, onClick, sx }) => {
  const handleClick = () => {
    onClick(word);
  };

  return (
    <Box
      sx={{
        px: 1.4,
        py: 0.75,
        bgcolor: "white",
        maxWidth: 150,
        border: "1px solid #E6E8F3",
        borderRadius: "14px",
        fontSize: 12,
        fontWeight: 900,
        cursor: "pointer",
        "&:hover": {
          bgcolor: "#F5F6FA",
        },
        // mt: 1,
        ...sx,
      }}
      onClick={handleClick}
    >
      {word}
    </Box>
  );
};

// ------------------------------------------------------------
// LEFT COMLUMN

const LeftIntro = ({ setChildStep }) => {
  return (
    <>
      <Stack spacing={3}>
        <Stack spacing={2} mt={3}>
          <Typography variant="text">
            Let's create your own crypto wallet to receive crypto from your customers.
          </Typography>
          <Typography variant="text">You will have full control over your wallet.</Typography>
          <Typography variant="text">Fizen Pay cannot access the funds in it.</Typography>
        </Stack>
        {isTestnet && (
          <Stack direction="row" spacing={1} alignItems="flex-start" xs={{ mt: 1 }}>
            <Box component="img" src={commonIcons.warning} alt="" />
            <Typography variant="text">
              This is the crypto wallet for your{" "}
              <Typography variant="text" sx={{ color: "warning.dark" }}>
                test account
              </Typography>
              . When you create a real account, you will need to set up another wallet.{" "}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack spacing={3} sx={{ mt: 3, lg: 0 }}>
        <ButtonContained fullWidth onClick={() => setChildStep(step.intro2)} sx={{ height: 50 }}>
          Create wallet
        </ButtonContained>
      </Stack>
    </>
  );
};
const LeftIntro2 = ({ handleGenerateSeedPhrase, setChildStep }) => {
  // const onBack = () => {
  //   setChildStep(step.intro)
  // }
  return (
    <>
      <Stack spacing={2} mt={3}>
        <Typography variant="text">
          A secret phrase acts as the key to a crypto wallet. With the secret phrase, anyone could
          have full control of the wallet.
        </Typography>
        <Typography variant="text">
          That's why you will need to store your secret phrase securely. DO NOT disclose it to
          anyone, including Fizen Pay.
        </Typography>
      </Stack>

      <Stack spacing={1} direction="row" sx={{ mt: 3, lg: 0 }}>
        {/*<ButtonOutlined width={1 / 10} sx={{ height: 50 }} onClick={onBack}>*/}
        {/*  <ArrowBack />*/}
        {/*</ButtonOutlined>*/}
        <ButtonContained
          fullWidth
          sx={{ height: 50, textTransform: "none" }}
          onClick={handleGenerateSeedPhrase}
        >
          Show me my secret phrase
        </ButtonContained>
      </Stack>
    </>
  );
};

const SeedPhrase = ({ setChildStep }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Stack spacing={2} mt={3}>
        <Typography variant="text">
          Write down your secret phrase and keep it somewhere safe.
        </Typography>
        <Typography variant="text">We do not store your secret phrase</Typography>
      </Stack>

      <Stack spacing={3}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label={
              <Typography variant="text">
                I understand that if I lose my secret phrase, I will lose my crypto wallet
              </Typography>
            }
          />
        </FormGroup>
        <ButtonContained
          fullWidth
          disabled={!checked}
          onClick={() => setChildStep(step.seedConfirm)}
          sx={{ height: 50 }}
        >
          OK, I've saved it securely
        </ButtonContained>
      </Stack>
    </>
  );
};

const SeedConfirm = ({
  setCurrentStep,
  chains,
  setChildStep,
  isCreatingWallet,
  verifyLoading,
  setVerifyLoading,
}) => {
  const seedPhrase = useRecoilValue(seedPhraseAtom);
  const seedPhraseInput = useRecoilValue(seedConfirmTextAtom);
  const interval = useRef();

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  const onBack = () => {
    setChildStep(step.seedPhrase);
    // setSeedPhraseInput("")
  };

  const onVerifySeedPhrase = async () => {
    try {
      if (chains.length === 0) return;
      setVerifyLoading(true);
      const wallets = chains.map((chain) => ({
        ...chain,
        address: ethers.Wallet.fromMnemonic(seedPhrase, chain.bip44Path + "/0").address,
      }));
      // setTimeout(() => console.log("Request taking a long time"), 60000 * 5);
      await SignUpService.setupWallet({ wallets });
      interval.current = setInterval(async () => {
        const res = await getProfile();
        if (res.data?.currentStep === stepsIndex.acceptCrypto) {
          setVerifyLoading(false);
          setCurrentStep(stepsIndex.acceptCrypto);
        }
      }, 2000);
    } catch (err) {
      console.log(err.message);
      setVerifyLoading(false);
    } finally {
    }
  };

  return (
    <>
      <Stack spacing={2} mt={3}>
        <Typography variant="text" sx={{ color: "text.subBody", fontWeight: 400 }}>
          Let's make sure you've saved your secret phrase correctly. Please select each word in the
          order it was presented to you on the previous screen.
        </Typography>
      </Stack>

      <Stack spacing={1.5} direction="row" sx={{ mt: { xs: 3, lg: 0 } }}>
        {!isCreatingWallet && (
          <MIconButton sx={{ height: 50, width: 50, background: "#E9E7FF" }} onClick={onBack}>
            <ArrowBack sx={{ color: "primary.main" }} />
          </MIconButton>
        )}

        <ButtonContained
          fullWidth
          sx={{ height: 50, textTransform: "none" }}
          onClick={onVerifySeedPhrase}
          loading={verifyLoading || isCreatingWallet}
          disabled={!isEqual(seedPhrase, seedPhraseInput)}
        >
          {isCreatingWallet ? `Creating wallet...` : `Verify secret phrase`}
        </ButtonContained>
      </Stack>
    </>
  );
};

// ---------------------------------------------------------------------
// RIGHT COLUMN

export const RightSeedPhrase = () => {
  const seedPhrase = useRecoilValue(seedPhraseAtom);

  return (
    <Box>
      <Typography component="p" variant="text" align="center">
        Your secret phrase:
      </Typography>
      <Box
        sx={{ bgcolor: "#F9FAFC", minHeight: { xs: 310, mac: 398 }, paddingX: 5 }}
        className="flex justify-center items-center text-center"
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {seedPhrase}
        </Typography>
      </Box>
    </Box>
  );
};

const blurStyle = {
  filter: "blur(4px)",
  webkitFilter: "blur(4px)",
};

export const RightSeedConfirm = ({ isCreatingWallet, verifyLoading }) => {
  const [seedConfirmText, setSeedConfirmText] = useRecoilState(seedConfirmTextAtom);
  const seedPhrase = useRecoilValue(seedPhraseAtom);
  const shuffleSeedPhrase = useMemo(
    () => shuffle(difference(seedPhrase.split(" "), seedConfirmText.split(" "))).join(" "),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [seedPhrase],
  );
  const [customSeedPhrase, setCustomSeedPhrase] = useState(shuffleSeedPhrase);
  const listWords = customSeedPhrase !== "" ? customSeedPhrase.split(" ") : [];
  const listConfirmWords = seedConfirmText !== "" ? seedConfirmText.split(" ") : [];

  const handleClickWord = (word) => {
    if (seedConfirmText.length === 0) {
      setSeedConfirmText(word);
    } else {
      setSeedConfirmText(seedConfirmText + " " + word);
    }

    const newCustomSeedPhrase = customSeedPhrase
      .split(" ")
      .filter((w) => w !== word)
      .join(" ");
    setCustomSeedPhrase(newCustomSeedPhrase);
  };

  const handleBackWord = (word) => {
    const newSeedConfirmText = seedConfirmText
      .split(" ")
      .filter((w) => w !== word)
      .join(" ");

    setSeedConfirmText(newSeedConfirmText);
    if (customSeedPhrase.length === 0) {
      setCustomSeedPhrase(word);
    } else {
      setCustomSeedPhrase(customSeedPhrase + " " + word);
    }
  };

  return (
    <Box
      sx={{ bgcolor: "#F9FAFC", minHeight: { xs: 310, mac: 398 }, width: "100%", paddingY: 2 }}
      className="flex flex-col w-full justify-start items-center text-center gap-3"
    >
      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
        Click to enter your secret phrase here:
      </Typography>
      <Box
        sx={{
          wordBreak: "break-word",
          border: "1px solid #C9CBDC",
          borderRadius: 1.5,
          overflow: "hidden",
          minHeight: 180,
          width: "100%",
          p: 2,
          fontSize: 12,
        }}
      >
        {listConfirmWords.length > 0 && (
          <Stack
            direction="row"
            spacing={0}
            flexWrap="wrap"
            alignContent="flex-start"
            sx={{ gap: 1 }}
          >
            {listConfirmWords.map((word, index) => (
              <React.Fragment key={index}>
                <BoxWord
                  word={word}
                  onClick={handleBackWord}
                  sx={{
                    bgcolor: "#9078F1",
                    color: "text.paper",
                    "&:hover": {
                      bgcolor: "violet.dark",
                    },
                    ...((isCreatingWallet || verifyLoading) && blurStyle),
                  }}
                />
              </React.Fragment>
            ))}
          </Stack>
        )}
      </Box>
      {listWords.length > 0 && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          spacing={0}
          flexWrap="wrap"
          alignContent="flex-start"
          sx={{ gap: 1 }}
        >
          {listWords.map((word, index) => (
            <React.Fragment key={index}>
              <BoxWord word={word} onClick={handleClickWord} />
            </React.Fragment>
          ))}
        </Stack>
      )}
      {/*<Typography variant="text" sx={{ fontStyle: "italic", color: warning ? "red" : "initial" }}>*/}
      {/*  {disabledPaste ? "Pasting is disabled for security reasons" : " "}*/}
      {/*</Typography>*/}
    </Box>
  );
};

const SetupWallet = ({ setCurrentStep, currentStep }) => {
  const [childStep, setChildStep] = useState(
    currentStep === stepsIndex.setupWalletProcessing ? step.seedConfirm : step.intro,
  );
  const [verifyLoading, setVerifyLoading] = useState();

  const [seedConfirmText, setSeedConfirmText] = useState();
  const setSeedPhrase = useSetRecoilState(seedPhraseAtom);
  const chains = useChainsSetting(
    currentStep === stepsIndex.setupWallet || currentStep === stepsIndex.setupWalletProcessing,
  );

  const fetchMerchantProfile = async () => {
    try {
      const res = await getProfile();
      if (res.data?.currentStep === stepsIndex.acceptCrypto) {
        setCurrentStep(stepsIndex.acceptCrypto);
      }
    } catch (err) {}
  };

  useEffect(() => {
    let interval;
    if (currentStep === stepsIndex.setupWalletProcessing) {
      // sxetChildStep(step.seedConfirm);
      interval = setInterval(() => {
        fetchMerchantProfile();
      }, 3000);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const handleBlock = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    setSeedConfirmText(e.target.value);
  };

  const handleGenerateSeedPhrase = async () => {
    try {
      let mnemonic = "";
      do {
        mnemonic = bip39.generateMnemonic();
      } while (!isNoDuplicate(mnemonic.split(" ")));
      setSeedPhrase(mnemonic);
      setChildStep(step.seedPhrase);
    } catch (err) {}
  };

  const RenderLeft = () => {
    switch (childStep) {
      case step.intro:
        return <LeftIntro setChildStep={setChildStep} />;
      case step.intro2:
        return (
          <LeftIntro2
            handleGenerateSeedPhrase={handleGenerateSeedPhrase}
            setChildStep={setChildStep}
          />
        );
      case step.seedPhrase:
        return <SeedPhrase setChildStep={setChildStep} />;
      case step.seedConfirm:
        return (
          <SeedConfirm
            setChildStep={setChildStep}
            setCurrentStep={setCurrentStep}
            chains={chains}
            isCreatingWallet={currentStep === stepsIndex.setupWalletProcessing}
            verifyLoading={verifyLoading}
            setVerifyLoading={setVerifyLoading}
          />
        );
      default:
        return <></>;
    }
  };

  const RenderRight = () => {
    switch (childStep) {
      case step.intro:
        return (
          <div className="flex justify-center items-center ">
            <MImage src="/assets/images/statics/wallet.png" />
          </div>
        );
      case step.intro2:
        return (
          <div className="flex justify-center items-center h-full">
            <MImage src="/assets/images/statics/security.png" />
          </div>
        );
      case step.seedPhrase:
        return <RightSeedPhrase />;
      case step.seedConfirm:
        return (
          <RightSeedConfirm
            value={seedConfirmText}
            handleChange={handleChange}
            handleBlock={handleBlock}
            disabledPaste={window.location.hostname !== "localhost"}
            isCreatingWallet={currentStep === stepsIndex.setupWalletProcessing}
            verifyLoading={verifyLoading}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <StepCard
      title="Set up your crypto wallet"
      leftCol={
        <div className={`flex-auto flex flex-col justify-between`}>
          <RenderLeft />
        </div>
      }
      rightCol={
        <Stack justifyContent="center" alignItems="center">
          <Box sx={{ height: { xs: "100%", mac: 398 }, width: "100%" }}>
            <RenderRight />
          </Box>
        </Stack>
      }
    />
  );
};

export default SetupWallet;
