import axiosMerchants from "utils/axios/merchant";
import axios, { handleResponse } from "utils/axios/public";
import { WalletsNetwork } from "../../../features/balances/types";

const signUp = async (
  captchaToken: string,
  data: {
    email: string;
    password: string;
    name: string;
    confirmedPassword: string;
    agreeTerm: number;
  },
) => {
  return axios({
    method: "post",
    url: "/merchants/register",
    data,
    headers: {
      recaptcha: captchaToken,
    },
  });
};

const getGoogleAuthenInfo = async () => {
  return axiosMerchants({
    method: "get",
    url: "/merchants/get-image-2fa",
  }).then(handleResponse);
};

const setup2FA = async (data: { fa_otp_token: string }) => {
  return axiosMerchants({
    method: "post",
    url: "/merchants/setup-2fa",
    data,
  });
};

const setupWallet = async (data: { wallets: WalletsNetwork[] }) => {
  return axiosMerchants({
    method: "post",
    url: "/merchants/setup-wallet",
    data,
    timeout: 60000 * 5,
  });
};

const downloadBackupCodes = async () => {
  return axiosMerchants({
    method: "get",
    url: "/merchants/backup-codes",
  }).then(handleResponse);
};

export { signUp, getGoogleAuthenInfo, setup2FA, setupWallet, downloadBackupCodes };
