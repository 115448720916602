function path(root: string, sublink: string): string {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_COMMERCE = "/commerce";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  signIn: path(ROOTS_AUTH, "/signin"),
  signUp: path(ROOTS_AUTH, "/signup"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  expired: path(ROOTS_AUTH, "/expired"),
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, "/home"),
  balances: path(ROOTS_DASHBOARD, "/balances"),
  payments: path(ROOTS_DASHBOARD, "/payments"),
  pos: path(ROOTS_DASHBOARD, "/pos"),
  invoices: path(ROOTS_DASHBOARD, "/invoices"),
  cryptoSetting: path(ROOTS_DASHBOARD, "/crypto-settings"),
  settings: path(ROOTS_DASHBOARD, "/settings"),
};

export const PATH_COMMERCE = {
  invoices: path(ROOTS_COMMERCE, "/invoices"),
};
