import { useEffect, useMemo, useRef, useState } from "react";
import { getCountdownTime } from "utils/datetime-formatter";

interface CountdownOptionsProps {
  isWaitingAfterCountDownEnd?: boolean;
  waitingAfterCountDownEndSeconds?: number;
  onCountDownEnd?: () => void;
  acceptCountDown?: boolean;
}

export function useCountdown(
  initialSeconds: number,
  {
    isWaitingAfterCountDownEnd = false,
    waitingAfterCountDownEndSeconds = 0,
    onCountDownEnd,
    acceptCountDown = true,
  }: CountdownOptionsProps = {},
) {
  const [cr, setCr] = useState(initialSeconds);
  const countdown = useMemo(() => getCountdownTime(cr), [cr]);
  const interval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    interval.current = setInterval(() => {
      if (acceptCountDown) {
        setCr((cr) => (cr - 1 <= 0 ? 0 : cr - 1));
      }
    }, 1000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [acceptCountDown]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (cr === 0) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      if (onCountDownEnd) {
        if (isWaitingAfterCountDownEnd) {
          timer = setTimeout(() => {
            onCountDownEnd();
          }, waitingAfterCountDownEndSeconds * 1000);
        } else {
          onCountDownEnd();
        }
      }
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cr, isWaitingAfterCountDownEnd, waitingAfterCountDownEndSeconds]);

  const resetCountdown = () => {
    setCr(initialSeconds);
  };

  return [cr, countdown, resetCountdown] as const;
}
