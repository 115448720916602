import React, { useContext, useState } from "react";

interface ModalWithClose {
  onClose: () => void;
}

type ModalProps<T = any> = T & ModalWithClose;

interface Modal {
  modalComponent?: React.ComponentType | null;
  modalNode?: React.ReactNode;
  modalProps?: any;
}

type ModalOpener = <M extends React.ComponentType<any>, P extends React.ComponentProps<M>>(
  modal?: M | null,
  modalProps?: Omit<P, "onClose">,
  modalNode?: React.ReactNode,
) => void;

interface ModalContextProps {
  openModal: ModalOpener;
  closeModal: () => void;
}

const ModalContext = React.createContext<ModalContextProps>({
  openModal: () => {},
  closeModal: () => {},
});

const ModalProvider: React.FC = ({ children }) => {
  const [ModalNode, setModalNode] = useState<Modal>();

  const onClose = () => {
    setModalNode(undefined);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal: (modal, modalProps, modalNode) => {
          setModalNode({
            modalComponent: modal,
            modalProps: modalProps,
            modalNode: modalNode,
          });
        },
        closeModal: onClose,
      }}
    >
      {ModalNode && (
        <>
          {ModalNode.modalComponent && (
            <ModalNode.modalComponent {...{ onClose, ...ModalNode.modalProps }} />
          )}
          {ModalNode.modalNode && ModalNode.modalNode}
        </>
      )}
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): ModalContextProps {
  return useContext(ModalContext);
}

export { ModalProvider, useModal };
export type { ModalProps, ModalOpener, Modal, ModalContextProps };
