import React, { useMemo } from "react";
// MUI
import { alpha, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
// hooks
import { useSettings } from "./ThemeSettings";
//
import componentsOverride from "./overrides";

const ThemePrimaryColor: React.FC = ({ children }) => {
  const defaultTheme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor,
      },
      customShadows: {
        // ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
    }),
    [setColor, defaultTheme],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemePrimaryColor;
