import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";

const ButtonOutlined = ({ title, children, onClick, loading, disabled, sx, size, ...props }) => {
  return (
    <Button
      {...props}
      loading={loading}
      onClick={onClick}
      variant="outlined"
      disabled={disabled || loading}
      size={size}
      sx={{
        borderColor: "text.subBody",
        color: "text.primary",
        ...sx,
      }}
    >
      {loading && <CircularProgress style={{ width: 15, height: 15, marginRight: 10 }} />}
      {title ? <Typography>{title}</Typography> : children}
    </Button>
  );
};

export default ButtonOutlined;
