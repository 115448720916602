import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { createContext, useEffect, useState } from "react";

interface CollapseDrawerContextProps {
  isCollapse: boolean;
  collapseClick: boolean;
  collapseHover: boolean;
  onToggleCollapse: () => void;
  onHoverEnter: () => void;
  onHoverLeave: () => void;
  onCollapse: () => void;
}

const initialState = {
  isCollapse: false,
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
  onCollapse: () => {},
};

const CollapseDrawerContext = createContext<CollapseDrawerContextProps>(initialState);

function CollapseDrawerProvider({ children }: { children: React.ReactNode | JSX.Element }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });

  useEffect(() => {
    if (isMobile) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isMobile]);

  const handleCollapse = () => {
    setCollapse({ ...collapse, click: true });
  };

  const handleToggleCollapse = () => {
    //console.log('zzz')
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    // if (collapse.click) {
    //     setCollapse({ ...collapse, hover: true });
    // }
  };

  const handleHoverLeave = () => {
    // setCollapse({ ...collapse, hover: false });
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
        onCollapse: handleCollapse,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
