import axios, { handleResponse } from "utils/axios/merchant";
import { CryptoCurrency, WalletsNetwork } from "../../../features/balances/types";
import { FlexibleSettings } from "../../../features/settings/types";
import { AutoWithdrawal, EmailPreferences } from "../../../recoil/types";

const updatePaymentSettings = (
  data:
    | { flexiblePaymentSettings: FlexibleSettings }
    | {
        autoWithdrawal?: AutoWithdrawal;
      },
) => {
  return axios({
    method: "post",
    url: "/merchants/update-payment-settings",
    data,
  }).then(handleResponse);
};

const getSupportCrypto = async () => {
  return await axios.get("/settings/crypto-currency").then(handleResponse);
};

const updateCryptoCurrency = async (data: { cryptoCurrency: CryptoCurrency[] }) => {
  return axios({
    method: "post",
    url: "/merchants/update-crypto-settings",
    data,
  });
};

const updateLocalCurrency = async (data: { localCurrency: string }) => {
  return axios({
    method: "post",
    url: "/merchants/update-local-currency-settings",
    data,
  });
};

const createMissingWallet = async (data: { wallets?: WalletsNetwork[] }) => {
  return axios({
    method: "post",
    url: "/merchants/create-wallet",
    data,
  });
};

const createApiKey = async () => {
  return axios.post(`/api-keys`);
};

const deleteApiKey = async (id: string) => {
  return axios.delete(`/api-keys/${id}`);
};

const verifySupportEmail = async (email: string) => {
  return axios.post(`/merchants/send-support-email-active-code?email=${encodeURIComponent(email)}`);
};

const changePassword = async (data: {
  oldPassword: string;
  newPassword: string;
  confirmedPassword: string;
}) => {
  return axios({
    method: "post",
    url: "/merchants/change-password",
    data,
  });
};

const change2FADevice = async (data: { authCode: string }) => {
  return axios({
    method: "post",
    url: "/merchants/change-2fa",
    data,
  }).then(handleResponse);
};

const confirm2FADevice = async (data: { fa_otp_token: string }) => {
  return axios({
    method: "post",
    url: "/merchants/confirm-2fa",
    data,
  });
};

const getBackupCodes = async (authenData: { authCode: string }) => {
  return axios({
    method: "post",
    url: "/merchants/get-backup-codes",
    data: authenData,
  }).then(handleResponse);
};

const closeAcount = async (data: { authCode: string }) => {
  return axios({
    method: "post",
    url: "/merchants/close-account",
    data,
  });
};

const generateMoreBackupCodes = async () => {
  return axios.put(`/merchants/backup-codes/generate`).then(handleResponse);
};

const updateEmailPreferences = async (emailPreferencesData: {
  emailPreferences: EmailPreferences;
}) => {
  return axios({
    method: "post",
    url: "/merchants/update-email-preferences",
    data: emailPreferencesData,
  });
};

const updateLogo = async (data: FormData) => {
  return axios({
    method: "post",
    url: "/merchants/upload-logo",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateShowLogo = async (data: { showLogo: boolean }) => {
  return axios({
    method: "put",
    url: "/merchants/update-show-logo",
    data,
  });
};

export {
  updatePaymentSettings,
  getSupportCrypto,
  updateCryptoCurrency,
  updateLocalCurrency,
  createMissingWallet,
  createApiKey,
  deleteApiKey,
  verifySupportEmail,
  changePassword,
  change2FADevice,
  confirm2FADevice,
  closeAcount,
  getBackupCodes,
  generateMoreBackupCodes,
  updateEmailPreferences,
  updateLogo,
  updateShowLogo,
};
