import { toast } from "react-toastify";
import { isTestnet } from "./configs";

const notifySuccess = (message: string) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: { top: isTestnet ? 20 : 0 },
  });
};

const notifyError = (error: string) => {
  toast.error(error, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: { top: isTestnet ? 20 : 0 },
  });
};

export { notifyError, notifySuccess };
