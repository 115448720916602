import axios from "axios";
import { apiPublicPrefix } from "utils/configs";
import { ErrorAPI } from "./errors";

// ----------------------------------------------------------------------

export function handleResponse(res: { data: any }) {
  if (!res.data) {
    return Promise.reject(new Error("Something went wrong"));
  } else {
    return Promise.resolve(res.data);
  }
}

const axiosPublicInstance = axios.create({
  baseURL: apiPublicPrefix,
});

axiosPublicInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(
      (error.response &&
        error.response.data &&
        new ErrorAPI(
          error.response.data.description,
          error.response.data.messageCode,
          error.response.data.description,
        )) ||
        error ||
        "Something went wrong",
    );
  },
);

export default axiosPublicInstance;
