import { ButtonContained } from "@fizen/ui-components";

export function ErrorFallback({ error }: { error: Error }) {
  const refresh = () => {
    window.location.reload();
  };

  if (error.message?.indexOf("Loading chunk") !== -1 && error.message?.indexOf("failed") !== -1) {
    return (
      <div
        className={`max-w-full break-words flex justify-center items-center flex-col min-h-screen`}
      >
        <p className={`font-extrabold text-3xl`}>We have some update! Refresh page now</p>
        <ButtonContained onClick={refresh}>Refresh page now</ButtonContained>
      </div>
    );
  }

  return (
    <div
      className={`max-w-full break-words flex justify-center items-center flex-col min-h-screen`}
    >
      <img src={`/assets/images/oops.png`} alt={``} className={`w-1/3`} />
      <p className={`font-extrabold text-3xl`}>Something went wrong</p>
      <p className={`font-bold text-center mt-5 border-2 mx-5 p-5`}>{error.message}</p>
      <ButtonContained onClick={refresh}>Refresh page now</ButtonContained>
    </div>
  );
}
