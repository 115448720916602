import { Link, SxProps, Theme } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface FizenLinkProps {
  to: string;
  sx?: SxProps<Theme>;
}

export function FizenLink({ to, children, sx }: React.PropsWithChildren<FizenLinkProps>) {
  return (
    <Link component={RouterLink} to={to} underline="none" sx={sx}>
      {children}
    </Link>
  );
}
