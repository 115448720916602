import { Outlet } from "react-router-dom";

function PointsOfSaleLayout() {
  // useEffect(() => {
  //   window.$crisp.push(["do", "chat:hide"]);
  // }, []);

  return <Outlet />;
}

export default PointsOfSaleLayout;
