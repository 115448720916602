const DEFAULT_PATH = "/assets/images/icons";

export const commonIcons = {
  checked: DEFAULT_PATH + "/check.svg",
  checkedPNG: DEFAULT_PATH + "/checked.png",
  unchecked: DEFAULT_PATH + "/uncheck.svg",
  checkedPurple: DEFAULT_PATH + "/check-purple.svg",
  copy: DEFAULT_PATH + "/copy.svg",
  processing: DEFAULT_PATH + "/processing.svg",
  arrowDown: DEFAULT_PATH + "/arrow-down.svg",
  arrowUp: DEFAULT_PATH + "/arrow-up.svg",
  arrowLeft: DEFAULT_PATH + "/arrow-left.svg",
  email: DEFAULT_PATH + "/email.png",
  warning: DEFAULT_PATH + "/warning.svg",
  redWarning: DEFAULT_PATH + "/red-warning.svg",
  doubleArrowDown: DEFAULT_PATH + "/double-arrow-down.svg",
  info: DEFAULT_PATH + "/info.svg",
  externalLink: DEFAULT_PATH + "/external-link.svg",
  look: DEFAULT_PATH + "/look.svg",
  trash: DEFAULT_PATH + "/trash.svg",
  search: DEFAULT_PATH + "/search.svg",
  filter: DEFAULT_PATH + "/filter.svg",
  switch: DEFAULT_PATH + "/switch.svg",
  share: DEFAULT_PATH + "/share.svg",
  edit: DEFAULT_PATH + "/edit.svg",
  qr: DEFAULT_PATH + "/qr.svg",
  lock: DEFAULT_PATH + "/lock.svg",
  unlock: DEFAULT_PATH + "/unlock.svg",
  delete: DEFAULT_PATH + "/delete.svg",
  telegram: DEFAULT_PATH + "/telegram.svg",
  facebookMessenger: DEFAULT_PATH + "/facebook-messenger.svg",
  whatsApp: DEFAULT_PATH + "/whatsapp.svg",
  discord: DEFAULT_PATH + "/discord.svg",
  line: DEFAULT_PATH + "/line.svg",
  facebook: DEFAULT_PATH + "/facebook.svg",
  viber: DEFAULT_PATH + "/viber.svg",
};

export const localesIcons = {
  vn: DEFAULT_PATH + "/locales/flag-vn.svg",
  us: DEFAULT_PATH + "/locales/flag-us.svg",
  th: DEFAULT_PATH + "/locales/flag-th.svg",
};
