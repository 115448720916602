import { Breakpoint, Theme, useMediaQuery } from "@mui/material";

interface MHiddenProps {
  children: JSX.Element;
  width: string;
}

export default function MobileHidden({ width, children }: MHiddenProps) {
  const breakpoint = width.substring(0, 2);

  const hiddenUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(breakpoint as Breakpoint));
  const hiddenDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint as Breakpoint),
  );

  if (width.includes("Down")) {
    return hiddenDown ? null : children;
  }

  if (width.includes("Up")) {
    return hiddenUp ? null : children;
  }

  return null;
}
