import { useState } from "react";

export function useLoading() {
  const [loading, setLoading] = useState<boolean>(false);

  const onLoading = (): void => {
    setLoading(true);
  };
  const onEnded = (): void => {
    setLoading(false);
  };

  return [loading, onLoading, onEnded] as const;
}
