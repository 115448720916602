import SvgIconStyle from "components/SvgIconStyle";
import { PATH_DASHBOARD } from "routes/path";

export type SidebarItem = {
  title: string;
  path: string;
  icon: JSX.Element;
  children?: any[];
  info?: any;
};

export interface SidebarConfig {
  subheader: string;
  items: SidebarItem[];
}

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/images/sidebar-icons/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

export const ICONS = {
  root: getIcon("root"),
  balances: getIcon("balances"),
  payments: getIcon("payments"),
  invoices: getIcon("invoices"),
  pos: getIcon("pos"),
  cryptoSettings: getIcon("cryptoSettings"),
  settings: getIcon("settings"),
};

const sidebarConfig: SidebarConfig[] = [
  {
    subheader: " ",
    items: [
      // {
      //   title: "Dashboard",
      //   path: PATH_DASHBOARD.root,
      //   icon: ICONS.root,
      // },
      {
        title: "Balances",
        path: PATH_DASHBOARD.balances,
        icon: ICONS.balances,
      },
      {
        title: "Payments",
        path: PATH_DASHBOARD.payments,
        icon: ICONS.payments,
      },
      {
        title: "Invoices",
        path: PATH_DASHBOARD.invoices,
        icon: ICONS.invoices,
      },
      {
        title: "Points of Sale",
        path: PATH_DASHBOARD.pos,
        icon: ICONS.pos,
      },
      {
        title: "Account settings",
        path: PATH_DASHBOARD.settings,
        icon: ICONS.settings,
      },
      {
        title: "Crypto settings",
        path: PATH_DASHBOARD.cryptoSetting,
        icon: ICONS.cryptoSettings,
      },
    ],
  },
];

export default sidebarConfig;
