import { getChainsInfo } from "pages/settings/services";
import { useCallback, useEffect, useState } from "react";

export function useChainsSetting(condition = true) {
  const [chains, setChains] = useState([]);

  console.log({ condition });

  const fetchChainsSetting = useCallback(async () => {
    if (!condition) return [];
    try {
      const res = await getChainsInfo();
      return res.data;
    } catch (err) {
      return [];
    }
  }, [condition]);

  useEffect(() => {
    fetchChainsSetting().then(setChains);
  }, [fetchChainsSetting]);

  return chains;
}
