import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { isSafari } from "react-device-detect";

// const oldUrl = window.location.href;

// const handleRedirect = (url) => {
//   let link = document.createElement("a");
//   link.href = url;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

function IosRedirect() {
  const [query] = useSearchParams();

  const uriSchema = useMemo(() => query.get("deeplink"), [query]);
  const fallBackUrl = useMemo(() => query.get("fallback"), [query]);

  // const oldUrl = useMemo(() => window.location.href, []);
  // useEffect(() => {
  //   const focusEvent = () => window.close();
  //   window.addEventListener("focus", focusEvent);
  //
  //   return () => {
  //     window.removeEventListener("focus", focusEvent);
  //   };
  // }, []);
  useEffect(() => {
    let timer;
    if (uriSchema && fallBackUrl) {
      if (!isSafari) {
        alert(222);
        // const time = new Date().getTime();
        document.location = uriSchema;
        timer = setTimeout(() => {
          document.location = fallBackUrl;
        }, 1000);
      } else {
        // handleRedirect(uriSchema); // will result in error message if app not installed
        // setTimeout(function () {
        //   // Link to the App Store should go here -- only fires if deep link fails
        //   // alert("Current href:" + window.location.href + "-- Old: " + oldUrl);
        // }, 500);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [uriSchema, fallBackUrl]);

  return <></>;
}

export default IosRedirect;
