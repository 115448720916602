import {
  Box,
  CircularProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { isString } from "lodash";
import React from "react";
import { NoResults } from "../NoResults";
import { PrimaryKeyProps, TableHeaderProps, TableProps, TableRowProps } from "./types";

const Header = <T extends {}>({
  columns,
  headerProps,
}: React.PropsWithChildren<TableHeaderProps<T>>) => {
  return (
    <TableHead sx={{ bgcolor: "#EEEBFF", ...headerProps }}>
      <TableRow>
        {columns.map(({ header: HeaderComponent, headerLabel, headerStyle }, idx) => (
          <TableCell
            key={headerLabel}
            sx={{
              "&.MuiTableCell-root": { padding: "16px 40px" },
              borderTopLeftRadius: idx === 0 ? 6 : 0,
              borderTopRightRadius: idx === columns.length - 1 ? 6 : 0,
              ...headerStyle,
            }}
          >
            <HeaderComponent label={headerLabel} />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const CustomRow = <T extends {}>({ row, columns, rowProps }: TableRowProps<T>) => (
  <TableRow
    {...(rowProps || {})}
    sx={{
      cursor: rowProps ? "pointer" : "default",
      bgcolor: "background.paper",
    }}
  >
    {columns.map(({ cell: Content, align, sx }, idx) => (
      <TableCell
        key={idx}
        align={align}
        sx={{ "&.MuiTableCell-root": { padding: "16px 40px" }, ...sx }}
      >
        <Content {...row} />
      </TableCell>
    ))}
  </TableRow>
);

export default function Table<T, K extends keyof T, S>({
  data = [],
  columns,
  noResults,
  primaryKey,
  pagination,
  handleChangePage,
  handleChangeRowsPerPage,
  sxTable,
  deriveRowProps,
  loading,
  hideHeader = false,
  headerProps,
}: TableProps<T, K, S>) {
  return (
    <>
      <TableContainer>
        <MuiTable sx={{ ...sxTable }}>
          {!hideHeader && <Header<T> columns={columns} headerProps={headerProps} />}
          {!loading && (
            <TableBody>
              {data.map((row) => (
                <CustomRow
                  key={
                    isString(primaryKey)
                      ? (row[primaryKey as K] as any)
                      : (`${(primaryKey as PrimaryKeyProps<K>).prefix}-${
                          row[(primaryKey as PrimaryKeyProps<K>).key]
                        }` as any)
                  }
                  row={row}
                  columns={columns}
                  rowProps={deriveRowProps ? deriveRowProps(row) : undefined}
                />
              ))}
            </TableBody>
          )}
        </MuiTable>
        {!loading && data.length === 0 && <>{noResults ? <Box>{noResults}</Box> : <NoResults />}</>}
        {loading && (
          <div className="flex justify-center items-center mt-5 h-[100px]">
            <CircularProgress size={30} />
          </div>
        )}
        {pagination && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={pagination.total}
            page={pagination.page - 1}
            onPageChange={handleChangePage as any}
            rowsPerPage={pagination.limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
            // labelDisplayedRows={({ from, to, count }) => }
          />
        )}
      </TableContainer>
    </>
  );
}
