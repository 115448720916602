import { useState } from "react";

export const useModal = () => {
  const [visible, setVisible] = useState(false);

  const onShow = () => setVisible(true);
  const onHide = () => setVisible(false);

  return [visible, onShow, onHide] as const;
};
