import { keyBy } from "lodash";

export type Network =
  | "binance-smart-chain"
  | "binance-smart-chain-testnet"
  | "ethereum-ropsten"
  | "ethereum-rinkeby"
  | "ethereum-sepolia"
  | "ethereum"
  | "polygon-mumbai"
  | "polygon"
  | "solana"
  | "solana-devnet"
  | "arbitrum"
  | "arbitrum-sepolia"
  | "optimism"
  | "optimism-sepolia"
  | "polygon-zkevm"
  | "polygon-zkevm-cardona"
  | "tron"
  | "tron-nile-testnet"
  | "avalanche"
  | "avalanche-fuji"
  ;

type NativeCurrencyProps = {
  name: string;
  symbol?: string;
  decimals?: number;
};

interface NetworkProps {
  name: string;
  shortName: string;
  scanLink: string;
  hex: string;
  decimal: string;
  nativeCurrency: NativeCurrencyProps;
  rpcUrls?: string[];
  blockExplorerUrls?: string[];
  tokens?: any;
}

export const networkConfig: Record<Network, NetworkProps> = {
  "binance-smart-chain": {
    name: "Binance Smart Chain",
    shortName: "BSC",
    scanLink: "https://bscscan.com/tx",
    hex: "0x38",
    decimal: "56",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "binance-smart-chain-testnet": {
    name: "BSC Testnet",
    shortName: "BSC Testnet",
    scanLink: "https://testnet.bscscan.com/tx",
    hex: "0x61",
    decimal: "97",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    tokens: {
      USDK: {
        address: '0x3eb36be2c3FD244139756F681420637a2a9464e3'
      }
    }
  },
  "ethereum-ropsten": {
    name: "Ropsten",
    shortName: "Ropsten",
    scanLink: "https://ropsten.etherscan.io/tx",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0x3",
    decimal: "3",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "ethereum-rinkeby": {
    name: "Rinkeby",
    shortName: "Rinkeby",
    scanLink: "https://rinkeby.etherscan.io/tx",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0x4",
    decimal: "4",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  ethereum: {
    name: "Ethereum",
    shortName: "Ethereum",
    scanLink: "https://etherscan.io/tx",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0x1",
    decimal: "1",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "polygon-mumbai": {
    name: "Polygon Mumbai Testnet",
    shortName: "Polygon Mumbai",
    scanLink: "https://mumbai.polygonscan.com/tx",
    hex: "0x13881",
    decimal: "80001",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygontestapi.terminet.io/rpc"],
    blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com/"],
    tokens: {
      USDK: {
        address: "0x95A94Fc761F98fcC60DA07C55F8ECDDD8B381CfF"
      }
    }
  },
  polygon: {
    name: "Polygon",
    shortName: "Polygon",
    scanLink: "https://polygonscan.com/tx",
    hex: "0x89",
    decimal: "137",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  solana: {
    name: "Solana",
    shortName: "Solana",
    scanLink: "https://solscan.io/tx",
    hex: "solana",
    decimal: "solana",
    nativeCurrency: {
      name: "SOL",
      symbol: "SOL",
      decimals: 9,
    },
    rpcUrls: [""],
    blockExplorerUrls: [""],
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "solana-devnet": {
    name: "Solana Devnet",
    shortName: "Solana Devnet",
    scanLink: "https://solscan.io/tx",
    hex: "solana",
    decimal: "solana",
    nativeCurrency: {
      name: "SOL",
      symbol: "SOL",
      decimals: 9,
    },
    rpcUrls: [""],
    blockExplorerUrls: [""],
    tokens: {
      USDK: {
        address: "9YSFWfU9Ram6mAo2QP9zsTnA8yFkkkFGEs3kGgjtQKvp"
      }
    }
  },
  "ethereum-sepolia": {
    name: "Ethereum Sepolia",
    shortName: "Ethereum Sepolia",
    scanLink: "https://sepolia.etherscan.io/tx",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0xaa36a7",
    decimal: "11155111",
    tokens: {
      USDK: {
        address: "0x5FF59Bf2277A1e6bA9bB8A38Ea3F9ABfd3d9345a"
      }
    }
  },
  arbitrum: {
    name: "Arbitrum",
    shortName: "Arbitrum",
    scanLink: "",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0xa4b1",
    decimal: "42161",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "arbitrum-sepolia": {
    name: "Arbitrum Sepolia",
    shortName: "Arbitrum Sepolia",
    scanLink: "",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0x66eee",
    decimal: "421614",
    tokens: {
      USDK: {
        address: "0x2cf79df2879902a2fc06329b1760e0f2ad9a3a47"
      }
    }
  },
  optimism: {
    name: "Optimism",
    shortName: "Optimism",
    scanLink: "",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0xa",
    decimal: "10",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "optimism-sepolia": {
    name: "Optimism sepolia",
    shortName: "Optimism sepolia",
    scanLink: "",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0xaa37dc",
    decimal: "11155420",
    tokens: {
      USDK: {
        address: "0x2cf79df2879902a2fc06329b1760e0f2ad9a3a47"
      }
    }
  },
  "polygon-zkevm": {
    name: "Polygon Zkevm",
    shortName: "Polygon Zkevm",
    scanLink: "",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0x44d",
    decimal: "1101",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "polygon-zkevm-cardona": {
    name: "Polygon Zkevm Cardona",
    shortName: "Polygon Zkevm Cardona",
    scanLink: "",
    nativeCurrency: {
      name: "ETH",
    },
    hex: "0x98a",
    decimal: "2442",
    tokens: {
      USDK: {
        address: "0x7D4325eE3A80778Af01498ca32E0C30e233ffB0d"
      }
    }
  },
  tron: {
    name: "Tron",
    shortName: "Tron",
    scanLink: "",
    nativeCurrency: {
      name: "TRX",
    },
    hex: "tron",
    decimal: "tron",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "tron-nile-testnet": {
    name: "Tron Nile",
    shortName: "Tron Nile",
    scanLink: "",
    nativeCurrency: {
      name: "TRX",
    },
    hex: "tron",
    decimal: "tron",
    tokens: {
      USDK: {
        address: "TEuRmCALTUY2syY1EE6mMYnyfmNfFfMpYz"
      }
    }
  },
  avalanche: {
    name: "Avalanche",
    shortName: "Avalanche",
    scanLink: "",
    nativeCurrency: {
      name: "AVAX",
    },
    hex: "0xa86a",
    decimal: "43114",
    tokens: {
      USDK: {
        address: null
      }
    }
  },
  "avalanche-fuji": {
    name: "Avalanche Fuji",
    shortName: "Avalanche Fuji",
    scanLink: "",
    nativeCurrency: {
      name: "AVAX",
    },
    hex: "0xa869",
    decimal: "43113",
    tokens: {
      USDK: {
        address: "0x5d8598Ce65f15f14c58aD3a4CD285223c8e76a2E"
      }
    }
  }
};

export const networkByIds = keyBy(Object.values(networkConfig), "decimal") ?? [];
export const networkIds = Object.keys(networkByIds).map(Number);
export const getNetworkName = <T extends keyof typeof networkConfig>(network: T) =>
  networkConfig[network]?.name ?? network;
export const getNetworkShortName = <T extends keyof typeof networkConfig>(network: T) =>
  networkConfig[network]?.shortName ?? network;
