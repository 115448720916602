import { DialogContentProps, SxProps, Theme } from "@mui/material";
import { Modal, ModalProps } from "../../widgets/Modal";
import { GradientText } from "../Typography";
import Form from "./Form";
import { FormBaseProps } from "./types";

type FormModalProps<T> = ModalProps<FormBaseProps<T>> & {
  title: string;
  sx?: SxProps<Theme>;
  DialogContentProps?: DialogContentProps;
};

function FormModal<T>({
  onClose,
  title,
  formModel,
  onFinish,
  initialValue,
  formButton,
  validationSchema,
  form,
  sx,
  DialogContentProps = { sx: { px: 10 } },
}: FormModalProps<T>) {
  return (
    <Modal onClose={onClose} sx={sx} title="" DialogContentProps={DialogContentProps}>
      <GradientText component="p" align="center" variant="big" mb={2}>
        {title}
      </GradientText>
      <Form
        formModel={formModel}
        initialValue={initialValue}
        formButton={formButton}
        validationSchema={validationSchema}
        onFinish={onFinish}
        form={form}
      />
    </Modal>
  );
}

export default FormModal;
