import { ButtonIcon } from "@fizen/ui-components";
import { Box, Divider, MenuItem, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import MenuPopover from "components/MenuPopover";
import MyAvatar from "components/dashboard/MyAvatar";
import useAuth from "hooks/useAuth";
import useIsMountedRef from "hooks/useIsMountedRef";
import { useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { generateReportProps, userProfile } from "recoil/atom";

const AccountPopover = () => {
  const { user } = useRecoilValue(userProfile);
  const setGenerateReport = useSetRecoilState(generateReportProps);

  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenGenerateReport = () => {
    setGenerateReport({ visible: true });
    handleClose();
  };

  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography
        // ref={anchorRef}
        variant="text"
        sx={{ color: "text.primary", cursor: "pointer", display: { xs: "none", md: "initial" } }}
        onClick={handleOpen}
      >
        {user?.email}
      </Typography>
      <ButtonIcon
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[400], 0.5),
            },
          }),
        }}
      >
        <MyAvatar />
      </ButtonIcon>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.email}
          </Typography>
          {/*<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>*/}
          {/*  {user.name}*/}
          {/*</Typography>*/}
        </Box>

        <Divider sx={{ my: 1, mx: 2 }} />

        <MenuItem
          onClick={handleOpenGenerateReport}
          sx={{ typography: "body2", py: 1, px: 2.5, fontWeight: 700 }}
        >
          Reports
        </MenuItem>
        <Divider sx={{ my: 1, mx: 2 }} />
        <MenuItem
          onClick={handleLogout}
          sx={{ typography: "body2", py: 1, px: 2.5, color: "primary.main", fontWeight: 700 }}
        >
          Log out
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
