import { Controller, useForm } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { isFunction } from "lodash";
import NumberFormat from "react-number-format";
import React from "react";
import FizenInput from "components/form/FizenInput";

function Form({
  formModel,
  onFinish,
  initialValue,
  formButton,
  validationSchema,
  form,
  // style = "light",
}) {
  const privateForm = useForm({
    defaultValues: initialValue,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const {
    // register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form ?? privateForm;

  const onSubmit = (data) => onFinish(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Object.entries(formModel).map(([key, value], idx) => {
        switch (value.type) {
          case "string":
            return (
              <Controller
                key={key}
                name={key}
                control={control}
                defaultValue={initialValue[key]}
                // rules={value.rules}
                render={
                  ({ field }) => (
                    // style === "light" ? (
                    //   <TextField
                    //     {...field}
                    //     error={!!errors[key]}
                    //     helperText={errors[key]?.message}
                    //     label={value.label}
                    //     sx={value.sx}
                    //     autoFocus={idx === 0}
                    //     variant="filled"
                    //   />
                    // ) : (
                    <FizenInput
                      {...field}
                      error={!!errors[key]}
                      helperText={errors[key]?.message}
                      label={value.label}
                      sx={value.sx}
                      autoFocus={idx === 0}
                    />
                  )
                  // )
                }
              />
            );
          case "password":
            return (
              <Controller
                key={key}
                name={key}
                control={control}
                defaultValue={initialValue[key]}
                // rules={value.rules}
                render={
                  ({ field }) => (
                    // style === "light" ? (
                    //   <TextField
                    //     {...field}
                    //     label={value.label}
                    //     sx={value.sx}
                    //     type="password"
                    //     error={!!errors[key]}
                    //     helperText={errors[key]?.message}
                    //     autoFocus={idx === 0}
                    //   />
                    // ) : (
                    <FizenInput
                      {...field}
                      label={value.label}
                      sx={value.sx}
                      type="password"
                      error={!!errors[key]}
                      helperText={errors[key]?.message}
                      autoFocus={idx === 0}
                      variant="filled"
                    />
                  )
                  // )
                }
              />
            );
          case "number":
            return (
              <Controller
                as={NumberFormat}
                thousandSeparator
                key={key}
                name={key}
                control={control}
                defaultValue={initialValue[key]}
                // rules={value.rules}
                render={({ field }) => {
                  return (
                    // <TextField
                    //     {...field}
                    //     error={!!errors[key]}
                    //     helperText={errors[key]?.message}
                    //     label={value.label}
                    //     sx={value.sx}
                    //     InputProps={{
                    //       inputComponent: NumberInput
                    //     }}
                    // />
                    <NumberFormat
                      {...field}
                      customInput={FizenInput}
                      onValueChange={(v) => {
                        console.log(v);
                        field.onChange(v.floatValue);
                      }}
                      value={field.value}
                      thousandSeparator
                      // isNumericString
                      decimalScale={value.decimalScale}
                      prefix={value.prefix}
                      suffix={value.suffix}
                      error={!!errors[key]}
                      helperText={errors[key]?.message}
                      label={value.label}
                      sx={value.sx}
                      autoFocus={idx === 0}
                      variant="filled"
                      allowNegative={value.allowNegative}
                    />
                  );
                }}
              />
            );
          case "select":
            return (
              <Controller
                key={key}
                name={key}
                control={control}
                defaultValue={initialValue[key]}
                // rules={value.rules}
                render={({ field: { onChange, ...props } }) => {
                  if (!value.options) {
                    value.options = [];
                  }
                  const index = value.options.findIndex((item) => item.value === props.value);
                  const init = value.options[index];
                  return (
                    <Autocomplete
                      sx={value.sx}
                      getOptionLabel={(option) => option.label}
                      options={value.options}
                      renderInput={(params) => <TextField {...params} label={value.label} />}
                      onChange={(e, data) => onChange(data?.value)}
                      value={init}
                    />
                  );
                }}
              />
            );
          default:
            return <></>;
        }
      })}
      {/*<input type="submit" />*/}
      {isFunction(formButton)
        ? formButton({ control, currentValue: watch(), setValue })
        : formButton}
    </form>
  );
}

export default Form;
