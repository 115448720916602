import { useEffect } from "react";
import { clientEndpoint } from "utils/configs";

export default function WithMerchantHost({ children }) {
  const isMerchantHost = clientEndpoint ? window.location.origin === clientEndpoint : true;

  useEffect(() => {
    if (!isMerchantHost && clientEndpoint) {
      const pathName = window.location.pathname;
      window.location.replace(`${clientEndpoint}${pathName}`);
    }
  }, [isMerchantHost]);

  if (!isMerchantHost) {
    return null;
  }

  return children;
}
