import MAvatar from "components/@mui-extends/MAvatar";
import { useRecoilValue } from "recoil";
import { userProfile } from "recoil/atom";
import createAvatar from "utils/createAvatar";

export default function MyAvatar({ ...other }) {
  const { user } = useRecoilValue(userProfile);

  return (
    <MAvatar color={createAvatar(user?.email ?? "")?.color} {...other}>
      {createAvatar(user?.email ?? "")?.name}
    </MAvatar>
  );
}
