import React, { useState } from "react";
// MUI
import { Box, CircularProgress, Stack, SxProps, Theme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { connection } from "recoil/atom";

interface MImageProps {
  src: string;
  sx?: SxProps<Theme>;
  alt?: string;
  asyncLoading?: boolean;
  [X: string]: any;
}

const MImage = ({ src, sx, alt = "", asyncLoading = true, ...props }: MImageProps) => {
  const [loading, setLoading] = useState(true);
  const isOnline = useRecoilValue(connection);

  if (!isOnline) {
    return (
      <Box
        {...props}
        component="img"
        src={"/assets/images/cryptos/no-image.png"}
        alt={alt}
        sx={sx}
      />
    );
  }

  const onImageLoaded = () => {
    setLoading(false);
  };

  if (!asyncLoading) {
    return (
      <Box
        {...props}
        component="img"
        src={src ?? "/assets/images/cryptos/no-image.png"}
        alt={alt}
        sx={sx}
        onError={({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>) => {
          currentTarget.onerror = null;
          currentTarget.src = "/assets/images/cryptos/no-image.png";
          setLoading(false);
        }}
      />
    );
  }

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ display: loading ? "flex" : "none", height: 20 }}
      >
        <CircularProgress size={20} />
      </Stack>

      <Box sx={{ display: loading ? "none" : "block" }}>
        <Box
          {...props}
          component="img"
          src={src ?? "/assets/images/cryptos/no-image.png"}
          alt={alt}
          onLoad={onImageLoaded}
          sx={sx}
          onError={({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/images/cryptos/no-image.png";
            setLoading(false);
          }}
        />
      </Box>
    </>
  );
};

export default React.memo(MImage);
