import React from "react";
import ButtonContained from "components/button/ButtonContained";

const ButtonInModal = ({ children, onClick, sx }) => {
  return (
    <ButtonContained
      onClick={onClick}
      sx={{
        width: "90%",
        color: "primary.main",
        bgcolor: "violet.lightest",
        "&:hover": { color: "text.paper" },
        ...sx,
      }}
    >
      {children}
    </ButtonContained>
  );
};

export default ButtonInModal;
