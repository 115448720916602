import React from "react";
import ThemeConfig from "./ThemeConfig";
import ThemePrimaryColor from "./ThemePrimaryColor";

const FizenProvider: React.FC = ({ children }) => {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>{children}</ThemePrimaryColor>
    </ThemeConfig>
  );
};

export default FizenProvider;
