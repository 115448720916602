import React from "react";
// MUI
import { GradientText } from "@fizen/ui-components";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  SxProps,
  Theme,
} from "@mui/material";

interface BasicDialogProps {
  open: boolean;
  title: string | React.ReactNode;
  onClose: () => void;
  divider?: React.ReactNode;
  DialogContentProps?: DialogContentProps & { [X: string]: any };
  DialogTitleProps?: DialogTitleProps & { textAlign?: string };
  sx?: SxProps<Theme>;
  options?: {
    closeIcon?: React.ReactNode;
    [X: string]: any;
  };
  [X: string]: any;
}

const BasicDialog = ({
  open,
  onClose,
  title,
  children,
  divider,
  DialogContentProps = { sx: { px: 10 } },
  DialogTitleProps = { sx: { px: 10 } },
  sx = {},
  options = {
    closeIcon: <CloseIcon />,
  },
  ...others
}: React.PropsWithChildren<BasicDialogProps>) => {
  return (
    <Dialog open={open} onClose={onClose} sx={sx} {...others}>
      <DialogTitle {...DialogTitleProps}>
        {options.closeIcon && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {options.closeIcon}
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent {...DialogContentProps}>
        {title && (
          <GradientText component="p" align="center" variant="big" mb={1.5}>
            {title}
          </GradientText>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default BasicDialog;
