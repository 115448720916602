// MUI
import { Box, Link, Stack, Typography } from "@mui/material";
// Component
import { ButtonContained } from "components/button";
import GoogleAuthen from "pages/signup/steps/GoogleAuthen";
import SetupWallet from "pages/signup/steps/SetupWallet";
import MImage from "components/@mui-extends/MImage";
import { commonIcons } from "utils/constants/icons";

export const stepsIndex = {
  verifyEmail: "CREATED",
  googleAuthen: "EMAIL_VERIFIED",
  setupWallet: "TWOFA_ENABLED",
  setupWalletProcessing: "WALLET_SETUP_PROGRESSING",
  acceptCrypto: "WALLET_SETUP_COMPLETED",
};

export const steps = ["Verify Email", "2-step verification", "Set up wallet", "You are ready!"];

export const stepsMapping = {
  "Verify Email": stepsIndex.verifyEmail,
  "2-step verification": stepsIndex.googleAuthen,
  "Set up wallet": stepsIndex.setupWallet,
  "You are ready!": stepsIndex.acceptCrypto,
};

export const StepCard = ({ title, leftCol, rightCol, sx }) => {
  return (
    <Box
      sx={{
        width: { xs: "95%", md: "80%", lg: "60%", xl: "50%" },
        marginX: "auto",
        bgcolor: "white",
        // marginTop: 3,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        ...sx,
      }}
    >
      {/*<Divider sx={{ marginTop: 2, marginBottom: 3 }} />*/}
      <Stack
        direction={{ xs: "column-reverse", lg: "row" }}
        // spacing={2}
        // divider={rightCol ? <Divider orientation="vertical" flexItem /> : null}
      >
        <div
          className={`flex flex-col py-6 px-10 ${
            rightCol ? "w-full xl:w-[60%]" : "w-full justify-center items-center"
          }`}
        >
          <Typography variant="big" sx={{ color: "#000000" }}>
            {title}
          </Typography>
          {leftCol}
        </div>
        {rightCol && (
          <Box
            sx={{
              width: { xs: "100%", lg: "40%" },
              bgcolor: "#F9FAFC",
              py: 3,
              px: 3,
              borderBottomRightRadius: 12,
            }}
          >
            {rightCol}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const ConfigStepRender = ({ currentStep, setCurrentStep }) => {
  switch (currentStep) {
    case stepsIndex.verifyEmail:
      return (
        <StepCard
          // title="Verify Email"
          leftCol={
            <div
              className={`flex-auto flex flex-col justify-between items-center gap-10 min-h-[200px] 2xl:min-h-[400px] 2xl:py-10`}
            >
              <MImage src={commonIcons.email} sx={{ maxWidth: 120, maxHeight: 120 }} />
              <Stack spacing={{ mac: 1 }}>
                <Typography
                  component="p"
                  variant="big"
                  sx={{ fontWeight: 600, textAlign: "center" }}
                >
                  Thank you for verifying your email address.
                </Typography>
                <Typography
                  component="p"
                  variant="text"
                  sx={{ fontWeight: 400, textAlign: "center" }}
                >
                  We will reach out to you shortly to get you whitelisted. You can also contact us
                  for support at{" "}
                  <Link href="mailto:business@fizen.io" underline="none">
                    business@fizen.io
                  </Link>
                  .
                </Typography>
              </Stack>

              <ButtonContained
                disabled
                sx={{ width: "40%" }}
                onClick={() => setCurrentStep((cr) => cr + 1)}
              >
                Continue
              </ButtonContained>
            </div>
          }
        />
      );
    case stepsIndex.googleAuthen:
      return <GoogleAuthen currentStep={currentStep} setCurrentStep={setCurrentStep} />;

    case stepsIndex.setupWallet:
    case stepsIndex.setupWalletProcessing:
      return <SetupWallet setCurrentStep={setCurrentStep} currentStep={currentStep} />;
    // case stepsIndex.backupWallet:
    default:
      return (
        <StepCard
          title="Secret Phrase verified"
          leftCol={
            <div className={`flex-auto flex flex-col justify-between mt-5`}>
              <Box sx={{ mb: { xs: 2, md: 0 } }}>
                <Stack spacing={2}>
                  <Typography variant="text">
                    You are now ready to accept crypto payments from your customers.
                  </Typography>
                  {/*<Typography variant="text">Welcome to Fizen Payment Gateways.</Typography>*/}
                </Stack>
                <Stack spacing={1} mt={3}>
                  <Typography variant="text">Download Fizen Wallet</Typography>
                  <Stack direction="row" flexWrap="wrap" sx={{ gap: 2 }}>
                    <Link
                      href="https://apps.apple.com/us/app/fizen-wallet-gamefi-payment/id1621269508"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Box
                        component="img"
                        src="/assets/images/statics/app-store.svg"
                        alt="download on google play"
                        sx={{
                          maxWidth: { xs: "170px", md: "100%" },
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                    <Link
                      href="https://play.google.com/store/apps/details?id=com.fizen.io.wallet"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Box
                        component="img"
                        src="/assets/images/statics/google-play.svg"
                        alt="download on google play"
                        sx={{
                          maxWidth: { xs: "170px", md: "100%" },
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </Stack>
                </Stack>
              </Box>
              {/*<Typography component="p" variant="text" sx={{ textAlign: "center", mb: 2 }}>*/}
              {/*  Let's get started!*/}
              {/*</Typography>*/}
              <div>
                <ButtonContained
                  fullWidth
                  sx={{ textTransform: "none", height: 50, mt: { xs: 0, md: 2, lg: 0 } }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Go to Dashboard
                </ButtonContained>
              </div>
            </div>
          }
          rightCol={
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%", minHeight: { xs: 310, mac: 398 } }}
            >
              {/*<Box minHeight={398}>*/}
              <MImage src="/assets/images/statics/wallet2.png" />
              {/*</Box>*/}
            </Stack>
          }
        />
      );
  }
};

export { ConfigStepRender };
