import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { isString } from "lodash";
import React from "react";

interface BannerProps {
  color?: string;
  title: string | React.ReactNode;
  sx?: SxProps<Theme>;
}

function Banner({ color = "#1F1849", title, sx }: React.PropsWithChildren<BannerProps>) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        minHeight: 36,
        bgcolor: color,
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
        ...sx,
      }}
    >
      {isString(title) ? <Typography variant="text500">{title}</Typography> : title}
    </Stack>
  );
}

export default Banner;
