import { forwardRef } from "react";
// MUI
import { IconButton } from "@mui/material";

const MIconButton = forwardRef(({ children, ...other }, ref) => (
  <IconButton ref={ref} {...other}>
    {children}
  </IconButton>
));

export default MIconButton;
