import { CreateInvoiceData } from "features/invoices/CreateInvoiceDialog";
import axios, { handleResponse } from "utils/axios/merchant";
import { SearchInvoice } from "../invoices";

const createInvoice = async (data: CreateInvoiceData) => {
  return axios({
    method: "post",
    url: "/invoices",
    data,
  }).then(handleResponse);
};

const voidInvoice = async (id: string, data: { remark?: string }) => {
  return axios.post(`/invoices/${id}/void`, data);
};

const refundInvoice = async (id: string) => {
  return axios.post(`/invoices/refunded/${id}`);
};

const resolveInvoice = async (id: string, data: { resolvedRemark: string }) => {
  return axios({
    method: "post",
    url: `/invoices/resolve/${id}`,
    data,
  }).then(handleResponse);
};

const getAllInvoices = async (query: { page: number; limit: number } & SearchInvoice) => {
  return axios({
    method: "get",
    url: "/invoices",
    params: query,
  }).then(handleResponse);
};

const getDetailInvoice = async (id: string) => {
  return axios.get(`/invoices/${id}`).then(handleResponse);
};

export {
  createInvoice,
  getAllInvoices,
  getDetailInvoice,
  voidInvoice,
  refundInvoice,
  resolveInvoice,
};
