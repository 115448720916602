import { forwardRef } from "react";
// MUI
import { ButtonContained } from "@fizen/ui-components";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextFieldProps,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
// Recoil
import { useRecoilState } from "recoil";
import { defaultGenerateReportProps, generateReportProps } from "recoil/atom";
// Hook
import dayjs from "dayjs";
import { timeOptions, transactionTypes, useGenerateReports } from "hooks/useGenerateReports";
import DatePicker from "react-datepicker";

const DatePickerInput = forwardRef<HTMLInputElement, TextFieldProps>(({ value, onClick }, ref) => {
  return (
    <TextField
      ref={ref}
      value={value ? dayjs(value as dayjs.Dayjs).format("DD/MM/YYYY") : ""}
      placeholder="From"
      sx={{ "& .MuiOutlinedInput-input": { height: 10 } }}
      onClick={onClick}
    />
  );
});

const GenerateReports = () => {
  const [props, setProps] = useRecoilState(generateReportProps);
  const {
    time,
    type,
    onChangeTime,
    onChangeType,
    value,
    setValue,
    isChooseCustom,
    onGenerateReport,
    loading,
  } = useGenerateReports();

  const onCancel = () => {
    setProps(defaultGenerateReportProps);
  };

  return (
    <Dialog open={props.visible} onClose={onCancel} sx={{ "& .MuiPaper-root": { width: 480 } }}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Generate transaction report
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider sx={{ mb: 3 }} />
        <div className="flex flex-col items-center gap-3">
          <Typography variant="body1" sx={{ color: "text.secondary", textAlign: "center", mb: 2 }}>
            Customize and export a file containing your account data. You can use this data for
            accounting purposes and analysis.
          </Typography>
          <Select fullWidth sx={{ height: 40 }} value={time} onChange={onChangeTime}>
            {timeOptions.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <Select fullWidth sx={{ height: 40 }} value={type} onChange={onChangeType}>
            {transactionTypes.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>

          {isChooseCustom && (
            <>
              <div className="flex justify-between items-center">
                <DatePicker
                  onChange={(date) => {
                    const newValue = [date, value[1]];
                    setValue(newValue);
                  }}
                  selected={value[0]}
                  selectsStart
                  startDate={value[0]}
                  endDate={value[1]}
                  placeholderText="From"
                  customInput={<DatePickerInput />}
                  maxDate={value[1]}
                />
                <Box sx={{ mx: 2 }}> to </Box>
                <DatePicker
                  onChange={(date) => {
                    const newValue = [value[0], date];
                    setValue(newValue);
                  }}
                  selected={value[1]}
                  selectsEnd
                  startDate={value[0]}
                  endDate={value[1]}
                  minDate={value[0]}
                  placeholderText="To"
                  customInput={<DatePickerInput />}
                  maxDate={new Date()}
                />
              </div>
            </>
          )}
          <ButtonContained
            fullWidth
            sx={{ height: 45, mt: 2 }}
            onClick={onGenerateReport}
            loading={loading}
          >
            Generate report
          </ButtonContained>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateReports;
