import { Web3ReactHooks, initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import type { Network } from '@web3-react/network';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { networkIds } from './configs';
export type ConnectorKinds = "injected" | "walletConnect" | "others";

export const POLLING_INTERVAL = 12000;

export function getConnector(
  connectorKind: ConnectorKinds,
) {
  if (connectorKind === "others") {
    throw new Error("Payment method is not allowed");
  }

  switch (connectorKind) {
    case "injected": {
      return metaMask;
    }
    case "walletConnect":
      return walletConnectV2;
  }
}

// const mainChainId = isTestnet ? 97 : 56;

export const [walletConnectV2, walletConnectHooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: "cd493f5efc1f5a594d57e713fb2f1c07",
        chains: [1],
        optionalChains: networkIds,
        showQrModal: true,
      },
    })
)

export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }))

export const connectors: [MetaMask | WalletConnectV2 | Network, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectHooks],
];