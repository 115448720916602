import dayjs from "dayjs";

export function formatterDate(date: dayjs.ConfigType, template = "DD-MM-YYYY, HH:mm") {
  return dayjs(date).format(template);
}

export const getCountdownTime = (cr: number) => {
  const date =
    parseInt(cr / 86400 + "") < 10
      ? "0" + Math.abs(parseInt(cr / 86400 + ""))
      : Math.abs(parseInt(cr / 86400 + ""));
  const hours =
    parseInt((cr - parseInt(date + "") * 86400) / 3600 + "") < 10
      ? "0" + Math.abs(parseInt((cr - parseInt(date + "") * 86400) / 3600 + ""))
      : parseInt(
          ((cr - parseInt(date + "") * 86400) / 3600 < 0
            ? 0
            : (cr - parseInt(date + "") * 86400) / 3600) + "",
        );

  const minutes =
    parseInt((cr - parseInt(date + "") * 86400 - parseInt(hours + "") * 3600) / 60 + "") < 10
      ? "0" +
        Math.abs(
          parseInt((cr - parseInt(date + "") * 86400 - parseInt(hours + "") * 3600) / 60 + ""),
        )
      : Math.abs(
          parseInt((cr - parseInt(date + "") * 86400 - parseInt(hours + "") * 3600) / 60 + ""),
        );

  const s = (cr - parseInt(date + "") * 86400 - parseInt(hours + "") * 3600) % 60;
  const seconds = s < 10 ? `0${s < 0 ? "0" : s}` : s;

  return { date, hours, minutes, seconds };
};
