import { ButtonContained, GradientText, Input } from "@fizen/ui-components";
import { Stack, Typography } from "@mui/material";
import { setRecoilExternalState } from "RecoilExternalState";
import BasicDialog from "components/dialog/BasicDialog";
import { useLoading } from "hooks/useLoading";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { default2FAProps, form2FAProps } from "recoil/atom";
import { IntegerInputFormat } from "utils/inputHandler";
import { notifyError } from "utils/notifications";
import { OkOptions } from "../../recoil/types";
import { ErrorAPI } from "../../utils/axios/errors";

interface Form2FAModalProps {
  title: string;
  buttonConfirmProps: any;
  acceptPassword?: boolean;
}

export const confirmWith2FA = (
  { title, buttonConfirmProps, acceptPassword = false }: Form2FAModalProps,
  onOK: (digitCode: string, { handleCancel, onLoad, onEnd, onError }: OkOptions) => void,
  onClose?: () => void,
) => {
  setRecoilExternalState(form2FAProps, {
    title,
    visible: true,
    onOK,
    onClose,
    buttonConfirmProps,
    acceptPassword,
  });
};

const getDisabledConfirmCondition = (digitCode?: string, acceptPassword?: boolean) => {
  if (!digitCode) {
    return false;
  }
  if (acceptPassword && digitCode !== "" && digitCode.length >= 6) {
    return true;
  }
  if (!acceptPassword && digitCode && digitCode.length === 6) {
    return true;
  }

  return false;
};

const Form2FaModal = () => {
  const [type, setType] = useState("2fa");
  const [digitCode, setDigitCode] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [prop, setProp] = useRecoilState(form2FAProps);
  const [loading, onLoad, onEnd] = useLoading();
  const [error, setError] = useState("");

  const { visible, title, onOK, onCancel, buttonConfirmProps, acceptPassword } = prop;

  const disabledConfirmCondition = getDisabledConfirmCondition(digitCode, acceptPassword);

  const handleChangeDigitCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "password") {
      setDigitCode(e.target.value);
    } else {
      setDigitCode(IntegerInputFormat(e.target.value, 6));
    }
    if (error) {
      setError("");
    }
  };

  const onError = (error: ErrorAPI) => {
    if (error.messageCode === "SECURITY.INVALID_OTP") {
      if (type === "password") {
        setError("Password is invalid");
      } else {
        setError("Authentication code is invalid");
      }
    } else {
      notifyError(error.message);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      setError("");
    } else {
      setProp(default2FAProps);
      setDigitCode("");
      setError("");
    }
  };

  const handleConfirmCode = () => {
    if (onOK) {
      onOK(digitCode, { handleCancel, onLoad, onEnd, onError });
    }
    // handleCancel();
  };

  // const handleClickShowPassword = () => {
  //   setShowPassword((prev) => !prev);
  // };
  //
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const changeInputMode = () => {
    if (type === "2fa") {
      setType("password");
    } else {
      setType("2fa");
    }
    setDigitCode("");
    if (error) {
      setError("");
    }
  };

  return (
    <BasicDialog
      open={visible}
      onClose={handleCancel}
      title=""
      sx={{ "& .MuiPaper-root": { width: 540 } }}
    >
      <GradientText component="p" align="center" variant="big" mb={1.5}>
        {title ?? `Two Factor Authentication Verify`}
      </GradientText>
      <Typography component="p" align="left" variant="text" mb={3}>
        {type === "2fa"
          ? `Input Google Authentication code to verify that it is you`
          : `Input your password to verify that it is you`}
      </Typography>
      <Stack spacing={3}>
        {type === "2fa" && (
          <Input
            fullWidth
            label="Enter Google Authentication code"
            autoFocus
            value={digitCode}
            onChange={handleChangeDigitCode}
            onKeyPress={(e) => {
              if (e.key === "Enter" && digitCode) {
                handleConfirmCode();
              }
            }}
            error={!!error}
            helperText={error}
          />
        )}
        {type === "password" && (
          <Input
            type="password"
            fullWidth
            label="Enter your password"
            autoFocus
            value={digitCode}
            onChange={handleChangeDigitCode}
            onKeyPress={(e) => {
              if (e.key === "Enter" && digitCode) {
                handleConfirmCode();
              }
            }}
            error={!!error}
            helperText={error}
            // InputProps={
            //   acceptPassword
            //     ? {
            //         endAdornment: (
            //           <InputAdornment position="end">
            //             <IconButton
            //               aria-label="toggle password visibility"
            //               onClick={handleClickShowPassword}
            //               onMouseDown={handleMouseDownPassword}
            //               edge="end"
            //             >
            //               {showPassword ? <VisibilityOff /> : <Visibility />}
            //             </IconButton>
            //           </InputAdornment>
            //         ),
            //       }
            //     : {}
            // }
          />
        )}
        <div className="flex flex-col gap-2 justify-end">
          <ButtonContained
            onClick={handleConfirmCode}
            disabled={!disabledConfirmCondition}
            loading={loading}
          >
            {buttonConfirmProps?.title ?? "Confirm"}
          </ButtonContained>
          {/*<Button onClick={handleCancel} sx={{ color: "text.primary" }}>*/}
          {/*  Cancel*/}
          {/*</Button>*/}
          {acceptPassword && (
            <Typography
              variant="text"
              onClick={changeInputMode}
              sx={{ cursor: "pointer", textAlign: "center" }}
            >
              {type === "2fa" ? "Use Password" : "Use Google Authentication code"}
            </Typography>
          )}
        </div>
      </Stack>
    </BasicDialog>
  );
};

export default Form2FaModal;
