import { Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { BaseButtonProps } from "./types";

const ButtonOutlined: React.FC<BaseButtonProps> = ({
  title,
  children,
  onClick,
  loading,
  disabled,
  sx,
  size = "medium",
  ...props
}): JSX.Element => {
  return (
    <Button
      {...props}
      onClick={onClick}
      variant="outlined"
      disabled={disabled || loading}
      size={size}
      sx={{
        borderColor: "text.subBody",
        color: "text.primary",
        ...sx,
      }}
    >
      {loading && <CircularProgress style={{ width: 15, height: 15, marginRight: 10 }} />}
      {title ? <Typography>{title}</Typography> : children}
    </Button>
  );
};

export default ButtonOutlined;
