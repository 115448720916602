import React, { useEffect, useState } from "react";
// MUI
import {
  alpha,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
// Component
import { ButtonContained } from "@fizen/ui-components";
import { FizenLink } from "components/FizenLink";
import Form from "components/form";
import Page from "components/Page";
import { googleRecaptchaKey } from "utils/configs";
// __API__
import * as SignUpService from "pages/signup/__api__";
// Package
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { authenticationPageTitles } from "utils/configs";
import * as yup from "yup";
import { ErrorAPI } from "../../utils/axios/errors";

const loadScript = (url: string) =>
  new Promise((resolve) => {
    const tag = document.createElement("script");
    tag.async = true;
    tag.defer = true;
    tag.src = url;
    const body = document.body;
    body.appendChild(tag);
    tag.addEventListener("load", resolve, {
      once: true,
    });
  });

const formModel = {
  email: {
    type: "string",
    label: "Email",
    name: "email",
    sx: {
      width: "100%",
      "& .MuiFilledInput-root": {
        transition: (theme: Theme) =>
          theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:hover": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
          boxShadow: (theme: Theme) => `${alpha(theme.palette.warning.dark, 0.25)} 0 0 0 2px`,
        },
        "&.Mui-focused": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
        },
      },
    },
  },
  password: {
    type: "password",
    label: "Password",
    name: "password",
    sx: {
      width: "100%",
      marginTop: 1.5,
      "& .MuiFilledInput-root": {
        transition: (theme: Theme) =>
          theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:hover": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
          boxShadow: (theme: Theme) => `${alpha(theme.palette.warning.dark, 0.25)} 0 0 0 2px`,
        },
        "&.Mui-focused": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
        },
      },
    },
  },
};

const validationSchema = yup.object().shape({
  email: yup.string().required("This field is required").email("Email is invalid"),
  password: yup
    .string()
    .required("This field is required")
    .min(8, "Password must be at least 8 characters")
    .max(24, "Password must be less than 24 characters"),
});

const FormWithReCaptcha = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(validationSchema),
  });

  const agreeToTermsAndPolicy = (e: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(e.target.checked);

  async function onSubmit(token: string) {
    try {
      const values = form.getValues();
      const formData = {
        ...values,
        email: values.email?.trim(),
        name: "Unknown",
        confirmedPassword: values.password,
        agreeTerm: 1, // 1: Agree 0: Disagree
      };
      await SignUpService.signUp(token, formData);
      navigate("/confirmation", { state: { email: values.email } });
    } catch (err) {
      if (err instanceof ErrorAPI) {
        if (err.message?.toLowerCase()?.indexOf("password") !== -1) {
          form.setError(
            "password",
            {
              message: err.message,
            },
            { shouldFocus: true },
          );
        } else {
          form.setError(
            "email",
            {
              message: err.message,
            },
            { shouldFocus: true },
          );
        }
      }
    } finally {
      window.grecaptcha.reset();
    }
  }

  const onCreateV2 = () => {
    window.grecaptcha.execute();
  };

  useEffect(() => {
    const ReCaptchaCallbackV3 = function () {
      window.grecaptcha?.ready(function () {
        window.grecaptcha.render("recaptcha", {
          sitekey: googleRecaptchaKey,
        });

        window.onSubmit = onSubmit;
      });
    };

    window.ReCaptchaCallbackV3 = ReCaptchaCallbackV3;

    void loadScript(
      "https://www.google.com/recaptcha/api.js?onload=ReCaptchaCallbackV3&render=explicit",
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {form.formState.errors?.afterSubmit && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {t(form.formState.errors?.afterSubmit?.message)}
        </Alert>
      )} */}
      <Form
        form={form}
        formModel={formModel}
        // style="dark"
        formButton={
          <Stack spacing={1.5}>
            <FormGroup sx={{ marginTop: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={checked}
                    onChange={agreeToTermsAndPolicy}
                    sx={{
                      color: "text.paper",
                      "&.Mui-checked": {
                        color: "primary.main",
                      },
                    }}
                  />
                }
                label={
                  <>
                    <Typography variant="text" sx={{ color: "white" }}>
                      I agree to the{" "}
                      <Link
                        href="https://fizen.io/terms-of-use"
                        //href="https://docs.google.com/document/d/16vrRQbe-cWAO-0NhI_KdU6snIW4EjQmS/edit"
                        target="_blank"
                        rel="noreferrer noopener"
                        underline="none"
                      >
                        <Typography
                          variant="text"
                          sx={{ color: "warning.dark", "&:hover": { color: "warning.dark" } }}
                        >
                          Terms of Use
                        </Typography>
                      </Link>
                      , and acknowledge the{" "}
                      <Link
                        href="https://fizen.io/privacy-policy"
                        //href="https://docs.google.com/document/d/16XUc8XxHIuXCLVj7CbROUOUpSH8H5HTG/edit?usp=sharing&ouid=102184397487238742419&rtpof=true&sd=true"
                        target="_blank"
                        rel="noreferrer noopener"
                        underline="none"
                      >
                        <Typography
                          variant="text"
                          sx={{ color: "warning.dark", "&:hover": { color: "warning.dark" } }}
                        >
                          Privacy Policy
                        </Typography>
                      </Link>
                      .
                    </Typography>
                  </>
                }
              />
            </FormGroup>

            <div
              id="recaptcha"
              className="g-recaptcha"
              data-size="invisible"
              data-callback="onSubmit"
            ></div>
            <ButtonContained
              id="submit"
              fullWidth
              sx={{ marginTop: 2.5, height: 50, textTransform: "none" }}
              type={`submit`}
              variant="contained"
              disabled={!checked}
            >
              Create account
            </ButtonContained>
          </Stack>
        }
        initialValue={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onFinish={onCreateV2}
      />
    </>
  );
};

function SignUp() {
  return (
    <Page title="Sign Up | Fizen Payment Gateway">
      <div className="flex w-full h-screen justify-center items-center flex-col gap-[12px] 2xl:gap-[24px]">
        <Typography sx={{ fontSize: 36, fontWeight: 600, color: "white" }}>
          {authenticationPageTitles}
        </Typography>
        <Box
          sx={{
            boxShadow: "rgb(0 0 0 / 6%) 0px 4px 4px, rgb(0 0 0 / 8%) 0px 8px 18px",
            width: { xs: 9 / 10, md: 650 },
            bgcolor: "rgba(255, 255, 255, 0.3)",
            borderRadius: "9px",
          }}
          py={{ xs: 3, mac: 5 }}
          px={{ xs: 3, lg: 8 }}
        >
          <Typography
            component="p"
            variant="big"
            sx={{ textAlign: "center", mb: 3, color: "text.paper" }}
          >
            Create Your Account
          </Typography>
          <FormWithReCaptcha />
          {/*</GoogleReCaptchaProvider>*/}
          <Stack
            marginTop={2}
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {/*<Typography variant="smallText">Already using Fizen Payment Gateway?</Typography>*/}
            <FizenLink to="/signin">
              <Typography variant="text" sx={{ color: "text.paper" }}>
                Sign in to your Fizen Pay account
              </Typography>
            </FizenLink>
          </Stack>
        </Box>
      </div>
    </Page>
  );
}

export default SignUp;
