import { useEffect, useMemo } from "react";
// Router
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { PATH_AUTH } from "routes/path";
//MUI
import { alpha, Box, Button, Theme, Typography } from "@mui/material";
// Component
import { ButtonContained, Form, FormModel } from "@fizen/ui-components";
import { FizenLink } from "components/FizenLink";
import Page from "components/Page";
import * as yup from "yup";
// Hooks
import useAuth from "hooks/useAuth";
import { useLoading } from "hooks/useLoading";
// Utils
import { notifyError, notifySuccess } from "utils/notifications";
import { ErrorAPI } from "../../utils/axios/errors";

const defaultValue = {
  password: "",
  confirmedPassword: "",
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmedPassword: yup
    .string()
    .required("Confirmed Password is required")
    .oneOf([yup.ref("password"), null], "Passwords and Confirm password must match"),
});

const formModel: FormModel = {
  password: {
    type: "password",
    label: "Password",
    sx: {
      width: "100%",
      "& .MuiFilledInput-root": {
        transition: (theme: Theme) =>
          theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:hover": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
          boxShadow: (theme: Theme) => `${alpha(theme.palette.warning.dark, 0.25)} 0 0 0 2px`,
        },
        "&.Mui-focused": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
        },
      },
    },
  },
  confirmedPassword: {
    type: "password",
    label: "Confirm Password",
    sx: {
      width: "100%",
      mt: 1.5,
      "& .MuiFilledInput-root": {
        transition: (theme: Theme) =>
          theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:hover": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
          boxShadow: (theme: Theme) => `${alpha(theme.palette.warning.dark, 0.25)} 0 0 0 2px`,
        },
        "&.Mui-focused": {
          backgroundColor: "white",
          borderColor: (theme: Theme) => theme.palette.warning.dark,
        },
      },
    },
  },
};

const NewPassword = () => {
  const { changeNewPassword, checkExpire } = useAuth();
  const [loading, onLoad, onEnded] = useLoading();
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const forgotPassword = useMemo(() => query.get("forgotPassword"), [query]);
  const email = useMemo(() => query.get("email"), [query]);

  useEffect(() => {
    const checkExpireToken = async () => {
      if (!email || !forgotPassword) {
        throw new ErrorAPI("Missing email or forgotPassword");
      }
      try {
        const res = await checkExpire({ email, code: forgotPassword });
        console.log(res);
      } catch (error) {
        if (error instanceof ErrorAPI) {
          if (error.messageCode === "MERCHANT.FORGOT_PASSWORD_EMAIL_EXPIRED") {
            navigate(PATH_AUTH.expired);
          } else {
            notifyError(error.message);
          }
        }
      }
    };

    if (email && forgotPassword) {
      checkExpireToken().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, forgotPassword]);

  if (!forgotPassword) {
    return <Navigate to={PATH_AUTH.signIn} />;
  }

  const onResetPassword = async (values: typeof defaultValue) => {
    try {
      const data = {
        ...values,
        forgotPassword,
      };
      onLoad();
      await changeNewPassword(data);
      notifySuccess("Reset password successfully");
      navigate(PATH_AUTH.signIn);
    } catch (err) {
      if (err instanceof ErrorAPI) {
        notifyError(err.message);
      }
    } finally {
      onEnded();
    }
  };

  return (
    <Page title="New Password | Fizen Payment Gateway">
      <div className="flex w-full h-screen justify-center items-center">
        <Box
          sx={{
            boxShadow: "rgb(0 0 0 / 6%) 0px 4px 4px, rgb(0 0 0 / 8%) 0px 8px 18px",
            width: { xs: 9 / 10, md: 650 },
            borderRadius: "9px",
            bgcolor: "background.grey",
          }}
          px={{ xs: 3, lg: 8 }}
          py={5}
        >
          <Typography
            component="p"
            variant="big"
            sx={{ textAlign: "center", mb: 3, color: "text.paper" }}
          >
            Reset password
          </Typography>
          <Form
            // style="dark"
            initialValue={defaultValue}
            formModel={formModel}
            validationSchema={validationSchema}
            formButton={({ currentValue }) => (
              <div className="flex flex-col gap-5 items-center mt-5">
                <ButtonContained
                  disabled={!currentValue.password || !currentValue.confirmedPassword}
                  fullWidth
                  sx={{ height: 50 }}
                  type="submit"
                  loading={loading}
                >
                  Reset password
                </ButtonContained>
                <FizenLink to={PATH_AUTH.signIn} sx={{ width: "100%" }}>
                  <Button fullWidth sx={{ color: "warning.dark" }}>
                    Cancel
                  </Button>
                </FizenLink>
              </div>
            )}
            onFinish={onResetPassword}
          />
        </Box>
      </div>
    </Page>
  );
};

export default NewPassword;
