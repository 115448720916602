import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// MUI
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// hooks
import useCollapseDrawer from "hooks/useCollapseDrawer";
// components
import Scrollbar from "components/Scrollbar";
import MobileHidden from "layouts/dashboard/MobileHidden";
import NavSection from "layouts/dashboard/NavSection";
//
import MImage from "components/@mui-extends/MImage";
import { isTestnet } from "utils/configs";
import sidebarConfig from "./sidebarConfig";

interface SidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

const DRAWER_WIDTH = 258;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function Sidebar({ isOpenSidebar, onCloseSidebar }: SidebarProps) {
  const { pathname } = useLocation();

  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100vh",
        "& .simplebar-content": {
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack justifyContent="space-between" height={1}>
        <Box>
          <Stack
            spacing={3}
            sx={{
              px: 2.5,
              pt: 3,
              pb: 2,
              ...(isCollapse && {
                alignItems: "center",
              }),
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                // spacing={1}
                component={RouterLink}
                to="/"
                sx={{ display: "flex", textDecoration: "none" }}
              >
                {/*  <ListItemIconStyle sx={{ width: 24, height: 24 }}>*/}
                {/*      <SvgIconStyle src="/assets/images/icons/logo.svg" color="primary" />*/}
                {/*  </ListItemIconStyle>*/}

                {/*<Typography sx={{ fontSize: 30, fontWeight: 700, color: "primary.main" }}>*/}
                {/*  FizenPay*/}
                {/*</Typography>*/}
                <MImage src="/assets/images/sidebar-icons/logo.png" />
              </Stack>

              {/*<MobileHidden width="lgDown">*/}
              {/*  {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}*/}
              {/*</MobileHidden>*/}
            </Stack>
          </Stack>

          <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

          <Box sx={{ flexGrow: 1 }} />
        </Box>

        {!isCollapse && (
          <Stack spacing={3} alignItems="center" sx={{ px: 1, mb: 5, textAlign: "center" }}>
            {/*<ButtonContained>Accept payments</ButtonContained>*/}
            <Typography variant="smallText" sx={{ color: "text.subBody" }}>
              © 2022 Fizen Pay. All Rights reserved
            </Typography>
          </Stack>
        )}
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MobileHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, top: isTestnet ? 30 : 0 },
          }}
        >
          {renderContent}
        </Drawer>
      </MobileHidden>

      <MobileHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => (theme as any).customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
              }),
              top: isTestnet ? 30 : 0,
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MobileHidden>
    </RootStyle>
  );
}
