import React from "react";
import { alpha, styled, TextField } from "@mui/material";

const FizenTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid white",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "white",
    borderColor: "#DBDBDB",

    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "white",
      borderColor: theme.palette.primary.main,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    },
    "&.Mui-focused": {
      backgroundColor: "white",
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.text.subBody,
    "&.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "&.Mui-error": {
      color: theme.palette.text.subBody,
    },
  },
}));

const FizenInput = ({ value, onChange, name, label, sx, ...props }) => {
  return (
    <FizenTextField
      {...props}
      value={value}
      onChange={onChange}
      name={name}
      margin="dense"
      label={label}
      fullWidth
      variant="filled"
      sx={{
        marginY: 1,
        ...sx,
      }}
      InputProps={{
        style: {
          // color: "white",
        },
        disableUnderline: true,
        ...props.InputProps,
      }}
      InputLabelProps={{
        style: {
          // color: "white",
        },
      }}
    />
  );
};

export default FizenInput;
