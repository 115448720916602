import { alpha } from "@mui/material/styles";

export interface DefaultColor {
  name?: string;
  lightest?: string;
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
  contrastText: string;
}

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const PRIMARY: DefaultColor = {
  lightest: "#C8FACD",
  lighter: "#C8FACD",
  light: "#5BE584",
  main: "#00AB55",
  dark: "#007B55",
  darker: "#005249",
  contrastText: "#fff",
};
const SECONDARY: DefaultColor = {
  lightest: "#D6E4FF",
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};
const INFO: DefaultColor = {
  lightest: "#CCDFFF",
  lighter: "#CCDFFF",
  light: "#6697FF",
  main: "#0045FF",
  dark: "#0027B7",
  darker: "#00137A",
  contrastText: "#fff",
};
const SUCCESS: DefaultColor = {
  lightest: "#E9FCD4",
  lighter: "#E9FCD4",
  light: "#8AC770",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};
const WARNING: DefaultColor = {
  lightest: "#FFF7CD",
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#E19635",
  darker: "#7A4F01",
  contrastText: GREY[800],
};
const ERROR: DefaultColor = {
  lightest: "#FFE7D9",
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#C11616",
  dark: "#DA1242",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const VIOLET = {
  lightest: "#EEE7F9",
  lighter: "#903AFD",
  light: "#492CFF",
  main: "#500EC1",
  dark: "#6304FF",
  darker: "#6304FF",
  contrastText: "#fff",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  violet: createGradient(VIOLET.lighter, VIOLET.light),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
  common: { black: "#282736", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  violet: { ...VIOLET },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: {
      primary: "#282736",
      secondary: "#282736",
      disabled: GREY[500],
      subBody: "#8089A9",
      subBody2: "#8089A9",
      paper: "#fff",
      black: "#000000",
      thirty: "#000000",
      forty: "#111127",
      fifty: "#393D56",
    },
    background: {
      paper: "#fff",
      default: "#fff",
      neutral: GREY[200],
      grey: "#8089A9",
      lightGrey: "#fff",
      main: "#FFFFFF",
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: {
      primary: "#FFFFFF",
      secondary: "#282736",
      disabled: GREY[600],
      grey: "rgba(255, 255, 255, 0.3)",
      subBody: "#8089A9",
      subBody2: "#ffffff",
      paper: "#fff",
      black: "#000000",
      thirty: "#ffffff",
      forty: "#FFFFFF",
      fifty: "#FFFFFF",
      lightGrey: "rgba(255, 255, 255, 0.2)",
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
      main: "#2A2A2F",
      grey: "rgba(255, 255, 255, 0.12)",
      lightGrey: "rgba(255, 255, 255, 0.3)",
    },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
