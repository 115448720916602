import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Stack, styled, Toolbar, Typography } from "@mui/material";
import AccountPopover from "components/dashboard/AccountPopover";
import useCollapseDrawer from "hooks/useCollapseDrawer";
import MobileHidden from "layouts/dashboard/MobileHidden";
import { useRecoilValue } from "recoil";
import { currentTabDashboard } from "recoil/atom";
import { isTestnet } from "utils/configs";

const DRAWER_WIDTH = 258;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "transparent",
  position: "absolute",
  top: isTestnet ? 80 : 0,
  // backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("md")]: {
    top: isTestnet ? 30 : 0,
  },
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 2.5),
  },
}));

export function NavBarHeader({ onOpenSidebar }: { onOpenSidebar: () => void }) {
  const { isCollapse } = useCollapseDrawer();
  const tabDashboard = useRecoilValue(currentTabDashboard);

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MobileHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
            <MenuIcon />
          </IconButton>
        </MobileHidden>
        <Typography sx={{ color: "text.primary", fontSize: { xs: 24, lg: 28 }, fontWeight: 700 }}>
          {tabDashboard}
        </Typography>

        {/*<Searchbar />*/}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/*<ButtonContained onClick={logout}>Sign Out</ButtonContained>*/}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
