import { forwardRef } from "react";
// MUI
import { Avatar, Palette, SxProps, Theme, useTheme } from "@mui/material";

type Colors =
  | keyof Pick<Palette, "primary" | "secondary" | "error" | "warning" | "info" | "success">
  | "default";

interface MAvatarProps {
  color?: Colors;
  sx?: SxProps<Theme>;
  [X: string]: any;
}

const MAvatar = forwardRef<any, MAvatarProps>(
  ({ color = "default", sx, children, ...other }, ref) => {
    const theme = useTheme();

    if (color === "default") {
      return (
        <Avatar ref={ref} sx={sx} {...other}>
          {children}
        </Avatar>
      );
    }

    return (
      <Avatar
        ref={ref}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
          ...sx,
        }}
        {...other}
      >
        {children}
      </Avatar>
    );
  },
);

export default MAvatar;
