import { forwardRef } from "react";
// MUI
import { ButtonProps, IconButton } from "@mui/material";

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...other }, ref): JSX.Element => {
    return (
      <IconButton ref={ref} {...other}>
        {children}
      </IconButton>
    );
  },
);

export default ButtonIcon;
