import { Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { BaseButtonProps } from "./types";

/*
 * Why shouldn't use React.FC?
 * See: https://github.com/facebook/create-react-app/pull/8177
 * But i think in our case, it would be fine
 * */

/*
 * ButtonContained and ButtonOutlined are the same. Maybe we should merge them
 * TODO: Considering and do merge
 * */
const ButtonContained: React.FC<BaseButtonProps> = ({
  title,
  children,
  onClick,
  disabled,
  loading,
  sx,
  size = "medium",
  ...props
}): JSX.Element => {
  return (
    <Button
      {...props}
      onClick={onClick}
      disabled={disabled || loading}
      variant="contained"
      size={size}
      sx={{
        "&:disabled": {
          bgcolor: "text.subBody",
          color: "text.paper",
        },
        ...sx,
      }}
    >
      {loading && (
        <CircularProgress style={{ width: 15, height: 15, color: "white", marginRight: 10 }} />
      )}
      {title ? <Typography>{title}</Typography> : children}
    </Button>
  );
};

export default ButtonContained;
